// S3Uploader.js
import { S3Client, PutObjectCommand } from "@aws-sdk/client-s3";

const S3Uploader = async ({ file, folder, onSuccess, onError }) => {
  const timestamp = Date.now();

  const bucketName = process.env.REACT_APP_AWS_S3_PRODUCT_IMAGE_BUCKET;
  const bucketRegion = process.env.REACT_APP_AWS_S3_BUCKET_REGION;
  const awsAccessKey =
    process.env.REACT_APP_AWS_TRAVEL_POCKET_PRODUCTS_ACCESS_KEY;
  const awsSecretKey =
    process.env.REACT_APP_AWS_TRAVEL_POCKET_PRODUCTS_SECRET_KEY;
  const bucketBaseUrl = process.env.REACT_APP_AWS_BUCKET_BASE_URL;

  const s3Client = new S3Client({
    region: bucketRegion,
    credentials: {
      accessKeyId: awsAccessKey,
      secretAccessKey: awsSecretKey,
    },
  });
  // Set the S3 parameters
  const params = {
    Bucket: bucketName,
    Key: `${folder}/${timestamp}_${file.name}`,
    Body: file,
    ContentType: file.type,
  };
  
  try {
    const command = new PutObjectCommand(params);
    const response = await s3Client.send(command);
    let fileUrl = `${bucketBaseUrl}/${encodeURIComponent(folder)}/${encodeURIComponent(timestamp + '_' + file.name)}`  
    if (response.$metadata.httpStatusCode === 200) {
      onSuccess({ fileUrl });
      return fileUrl;
  } else {
      return '';
  }
  } catch (error) {
    console.error("Error uploading file to S3:", error);
    onError(error);
    throw new Error("File upload failed.");
  }
};

export default S3Uploader;
