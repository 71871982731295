import React, { useEffect, useState } from 'react';
import { Button, Switch, Table } from 'antd';
import { DeleteFilled, EditFilled } from '@ant-design/icons';
import { updateDestination } from '../../api/destination';
import DeleteModal from '../Modal/DeleteModal';

const DestinationTable = ({ destinations, onDestinationSelected, onDestinationUpdated, searchText }) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [dataSource, setDataSource] = useState([]);
  const [isDestinationDeleting, setIsDestinationDeleting] = useState(false);
  const [destinationToDelete, setDestinationToDelete] = useState(null);
  const [filteredDataSource, setFilteredDataSource] = useState([]);

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const handleEditClick = (record) => {
    onDestinationSelected(record, 'edit');
  };

  const handleSwitchChange = async (checked, record) => {
    await updateDestination({
      id: record.id,
      isPublished: checked
    });
    onDestinationUpdated();
  };

  const handleDeleteClick = (record) => {
    setIsDestinationDeleting(true);
    setDestinationToDelete(record);
  };

  const onItemDeleted = (isItemDelted) => {
    if(isItemDelted) {
      onDestinationUpdated();
    }
  }

  const columns = [
    {
      title: 'S.No',
      dataIndex: 'sno',
      sorter: (a, b) => a.id > b.id ? 1 : (a.id < b.id ? -1 : 0),
    },
    {
      title: 'Name',
      dataIndex: 'name',
      sorter: (a, b) => a.name.length - b.name.length,
    },
    {
      title: 'Country',
      dataIndex: 'country',
      sorter: (a, b) => a.country.localeCompare(b.country),
    },
    {
      title: 'State',
      dataIndex: 'state',
      sorter: (a, b) => a.name.length - b.name.length,
    },
    {
      title: 'Publish',
      dataIndex: 'isPublished',
      render: (active, record) => {
        return (
          <Switch
            checked={active}
            onChange={(checked) => handleSwitchChange(checked, record)}
          />
        );
      },
    },
    {
      title: 'Actions',
      dataIndex: 'action',
      render: (text, record) => {
        return (
          <>
            <Button
              icon={
                <EditFilled style={{ fontSize: "20px", color: "#324966" }} />
              }
              style={{ marginRight: '15px' }}
              onClick={() => handleEditClick(record)}
            />
            <Button
              icon={<DeleteFilled style={{ fontSize: "20px", color: "red" }} />}
              onClick={() => handleDeleteClick(record)}
            />
          </>
        );
      },
    },
  ];

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const getDataObject = (destinations) => {
    const dataObject = [];
    for (let i = 0; i < destinations.length; i++) {
      const destination = destinations[i];
      const obj = {
        sno: i + 1,
        name: destination.name,
        isPublished: destination.isPublished,
        country: destination.country,
        state: destination.state,
        id:destination.id
      }
      dataObject.push(obj);
    }
    setDataSource(dataObject)
    setFilteredDataSource(dataObject);
  }

  function filterDestinationData(searchText) {
    if (dataSource.length && searchText) {
      const searchTextLower = searchText.toLowerCase();
      const filteredDestinations = dataSource.filter((destination) => {
        for (const key in destination) {
          if (
            destination[key] &&
            destination[key].toString().toLowerCase().includes(searchTextLower)
          ) {
            return true;
          }
        }
        return false;
      });
      setFilteredDataSource(filteredDestinations);
    } else {
      setFilteredDataSource(dataSource);
    }
  }

  useEffect(() => {
    getDataObject(destinations);
  }, [destinations])

  useEffect(() => {
    filterDestinationData(searchText);
  }, [searchText]);

  return (
    <div>
      <Table rowSelection={rowSelection} columns={columns} dataSource={filteredDataSource} scroll={{ x: 'max-content' }} />
      <DeleteModal isProductDeleting={isDestinationDeleting} deletingObj={destinationToDelete} onItemDeleted={onItemDeleted} setIsProductDeleting={setIsDestinationDeleting} table={'destination'} />
    </div>
  );
};
export default DestinationTable;