import React from "react";
import { Route, Routes } from "react-router-dom";
import DashBoardGraph from "../page/DashBoardGraph";
import CustomerPage from "../page/CustomerPage";
// import Invoice from "../page/Invoice";
import BookingsPage from "../page/BookingsPage";
import SettingPage from "../page/SettingPage";
import VendorsPage from "../page/VendorsPage";
import BookingsOfDemo from "../page/DemoBookings";
import Quotations from "../page/Quotations";
import DestinationPage from "../page/DestinationPage";
import TripTypePage from "../page/TripTypePage";
import ActivityTypePage from "../page/ActivityTypePage";
import TripsPage from "../page/TripsPage";

function AppRoutes() {
  return (
    <Routes>
      <Route path="/" element={<DashBoardGraph />}></Route>
      <Route path="/trips" element={<TripsPage />}></Route>
      <Route path="/destination" element={<DestinationPage />}></Route>
      <Route path="/activitytype" element={<ActivityTypePage />}></Route>
      <Route path="/triptype" element={<TripTypePage />}></Route>

      <Route path="/bookings" element={<BookingsPage />}></Route>
      <Route path="/settings" element={<SettingPage />}></Route>

      <Route path="/vendors" element={<VendorsPage />}></Route>
      <Route path="/customers" element={<CustomerPage />}></Route>
      <Route path="/quotations" element={<Quotations />}></Route>
      <Route path="/bookings-of-demo" element={<BookingsOfDemo />}></Route>
    </Routes>
  );
}
export default AppRoutes;
