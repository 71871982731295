export const allowedFileTypesForDestination = [
    "image/jpeg",
    "image/jpg",
    "image/png",
    "image/gif",
    "video/mp4",
    "video/webm",
  ];

export const allowedFileTypesForGallery = [
    "image/jpeg",
    "image/jpg",
    "image/png",
    "image/gif"
  ];