import React, { useEffect, useState } from "react";
import { UploadOutlined, PlusOutlined, MinusCircleOutlined } from "@ant-design/icons";
import dayjs from 'dayjs';
import {
  Button,
  Card,
  Col,
  Drawer,
  Input,
  Row,
  Select,
  Space,
  Upload,
  message,
  Form,
  DatePicker,
  InputNumber
} from "antd";
import { useForm } from "antd/lib/form/Form";
import { createTrips, updateTrips } from "../api/trips";
// import { getAllTripsType } from "../api/tripsType";
import { onSellingPriceChange, onOriginalPriceChange } from '../Utils/discountCalculation';
import { allowedFileTypesForDestination, allowedFileTypesForGallery } from "../Utils/allowFileType";
import S3Uploader from "./S3bucket/S3Uploader";
const { TextArea } = Input;

const AddTrips = ({
  isDrawerOpen,
  setIsDrawerOpen,
  destinationList,
  tripTypeList,
  activityList,
  selectedTrips,
  onTripsUpdated,
}) => {
  const [open, setOpen] = useState(isDrawerOpen);
  const [form] = useForm();
  let destinationDefaultFileList = [];
  let destinationUploadedFileList = [];
  let galleryDefaultFileList = [];
  let galleryUploadedFileList = [];
  let coverPhotoDefaultFileList = [];
  let coverPhotoUploadedFileList = [];
  let destinationImages = [];
  let galleryImages = [];
  let coverPhoto = [];

  // When selectedTrips is available, set it in the form
  if (selectedTrips) {
    form.resetFields();
    form.setFieldsValue(selectedTrips);
    selectedTrips?.destinationImages?.map((item, index) => {
      destinationImages.push(item);
      destinationDefaultFileList.push({
        uid: index + 1,
        status: "done",
        name: decodeURIComponent(item.split('/').pop()).replace(/\d+_/, ''),
        url: item,
      });
    });
    const coverPhotoData = selectedTrips?.coverPhoto
    coverPhoto.push(coverPhotoData);
    coverPhotoDefaultFileList.push({
      uid: 1,
      status: "done",
      name: decodeURIComponent(coverPhotoData.split('/').pop()).replace(/\d+_/, ''),
      url: coverPhotoData,
    });
    selectedTrips?.gallaryImages?.map((item, index) => {
      galleryImages.push(item);
      galleryDefaultFileList.push({
        uid: index + 1,
        status: "done",
        name: decodeURIComponent(item.split('/').pop()).replace(/\d+_/, ''),
        url: item,
      });
    });
  } else {
    form.resetFields();
  }



  // customrequest for the props
  const destinationCustomRequest = async ({ file, onSuccess, onError }) => {
    if (!file) {
      console.error("Please select a file.");
      return;
    }
    try {
      const folder = "destination_images"
      const fileUrl = await S3Uploader({ file, folder, onSuccess, onError });
      message.success("File uploaded successfully");
      destinationImages.push(fileUrl);
      return fileUrl;
    } catch (error) {
      message.error("File upload failed.");
    }
  };
  const galleryCustomRequest = async ({ file, onSuccess, onError }) => {
    if (!file) {
      console.error("Please select a file.");
      return;
    }
    try {
      const folder = "gallery_images"
      const fileUrl = await S3Uploader({ file, folder, onSuccess, onError });
      message.success("File uploaded successfully");
      galleryImages.push(fileUrl);
      return fileUrl;
    } catch (error) {
      message.error("File upload failed.");
    }
  };
  const coverPhotoCustomRequest = async ({ file, onSuccess, onError }) => {
    if (!file) {
      console.error("Please select a file.");
      return;
    }
    try {
      const folder = "cover_photo"
      const fileUrl = await S3Uploader({ file, folder, onSuccess, onError });
      message.success("File uploaded successfully");
      coverPhoto.push(fileUrl);
      return fileUrl;
    } catch (error) {
      message.error("File upload failed.");
    }
  };

  // form submission
  const onFinish = async (formData) => {
    try {
      const { destinationImgFile, galleryImgFile, coverPhotoFile, faq, ...otherFormData } = formData;
      const finalFormData = {
        destinationImages,
        gallaryImages: galleryImages,
        coverPhoto: coverPhoto[0],
        faq: faq.map((item, index) => ({ ...item, number: index + 1 })),
        color: 'blue',
        ...otherFormData
      };
      if (selectedTrips) {
        await updateTrips({ id: selectedTrips.id, ...finalFormData });
      } else {
        await createTrips(finalFormData);
      }
      onTripsUpdated();
      setIsDrawerOpen(false);
      console.log("Success:", finalFormData);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  const onClose = () => {
    setOpen(false);
    setIsDrawerOpen(false);
  };

  // file remove funtions
  const handleDestinationFileRemove = async (value) => destinationImages.filter((item) => item !== value.url);
  const handlegalleryFileRemove = async (value) => galleryImages.filter((item) => item !== value.url);
  const handleCoverPhotoFileRemove = async (value) => coverPhoto.filter((item) => item !== value.url);

  // image upload props
  const destinationImagesProps = {
    defaultFileList: destinationDefaultFileList,
    beforeUpload: (file) => {
      const isAllowedFileType = allowedFileTypesForDestination.includes(file.type);
      if (!isAllowedFileType) {
        message.error("You can only upload image or video.");
        return Upload.LIST_IGNORE;
      }
      if (file.type.startsWith("video/")) {
        const videoCount = destinationUploadedFileList.filter((f) => f.type.startsWith("video/")).length;
        if (videoCount >= 2) {
          message.error("You can only upload up to two videos.");
          return Upload.LIST_IGNORE;
        }
      }
      if (file.type.startsWith("image/")) {
        const imageCount = destinationUploadedFileList.filter((f) => f.type.startsWith("image/")).length;
        if (imageCount >= 8) {
          message.error("You can only upload up to 8 images.");
          return Upload.LIST_IGNORE;
        }
      }
      return true;
    },
    name: "destinationImgFile",
    action: "https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188",
    headers: {
      authorization: "authorization-text",
    },
    onChange(info) {
      if (info.file.status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
        const updatedFileList = [...destinationUploadedFileList];
        updatedFileList.push(info.file);
        destinationUploadedFileList = updatedFileList;
        console.log(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === "error") {
        console.log(`${info.file.name} file upload failed.`);
      }
    },
    customRequest: destinationCustomRequest,
    onRemove: (file) => handleDestinationFileRemove(file),
  };

  const galleryImagesProps = {
    defaultFileList: galleryDefaultFileList,
    beforeUpload: (file) => {
      const isAllowedFileType = allowedFileTypesForGallery.includes(file.type);
      if (!isAllowedFileType) {
        message.error("You can only upload images.");
        return Upload.LIST_IGNORE;
      }
      if (file.type.startsWith("image/")) {
        const imageCount = galleryUploadedFileList.filter((f) => f.type.startsWith("image/")).length;
        if (imageCount >= 10) {
          message.error("You can only upload up to 10 images.");
          return Upload.LIST_IGNORE;
        }
      }
      return true;
    },
    name: "galleryImgFile",
    action: "https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188",
    headers: {
      authorization: "authorization-text",
    },
    onChange(info) {
      if (info.file.status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
        const updatedFileList = [...galleryUploadedFileList];
        updatedFileList.push(info.file);
        galleryUploadedFileList = updatedFileList;
        console.log(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === "error") {
        console.log(`${info.file.name} file upload failed.`);
      }
    },
    customRequest: galleryCustomRequest,
    onRemove: (file) => handlegalleryFileRemove(file),
  };

  const coverPhotoProps = {
    defaultFileList: coverPhotoDefaultFileList,
    beforeUpload: (file) => {
      const isAllowedFileType = allowedFileTypesForGallery.includes(file.type);
      if (!isAllowedFileType) {
        message.error("You can only upload images.");
        return Upload.LIST_IGNORE;
      }
      if (file.type.startsWith("image/")) {
        if (coverPhotoUploadedFileList.some((f) => f.type.startsWith("image/"))) {
          message.error("You can only upload one cover photo.");
          return Upload.LIST_IGNORE;
        }
      }
      return true;
    },
    name: "coverPhotoFile",
    action: "https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188",
    headers: {
      authorization: "authorization-text",
    },
    onChange(info) {
      if (info.file.status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
        const updatedFileList = [...coverPhotoUploadedFileList];
        updatedFileList.push(info.file);
        coverPhotoUploadedFileList = updatedFileList;
        console.log(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === "error") {
        console.log(`${info.file.name} file upload failed.`);
      }
    },
    customRequest: coverPhotoCustomRequest,
    onRemove: (file) => handleCoverPhotoFileRemove(file),
  };

  //datepicker code
  const minDate = dayjs().startOf('day');
  const maxDate = dayjs().add(12, 'month').endOf('month');

  // useEffects
  useEffect(() => {
    setOpen(isDrawerOpen);
  }, [isDrawerOpen]);

  return (
    <>
      <Drawer
        title={
          <div>
            <Card.Meta
              title={<span style={{ marginBottom: "0" }}>{`${selectedTrips ? "Update" : "Add"} Trips`}</span>}
              description={
                <span style={{ fontWeight: "400", color: "#080305" }}>
                  {`${selectedTrips ? "Update" : "Add"} your trips and necessary information from here`}
                </span>
              }
            />
          </div>
        }
        width={690}
        onClose={onClose}
        maskClosable={false}
        open={open}
        styles={{
          body: {
            paddingBottom: 80,
          },
        }}
      >
        <Form
          form={form}
          layout="vertical"
          requiredMark={false}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}

        >
          <Row gutter={20}>
            <Col span={24}>
              <Form.Item
                name="tripTitle"
                label="Title of Trip"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Trip Title",
                  },
                ]}
              >
                <Input placeholder="Enter Trip Title " />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={20}>
            <Col span={24}>
              <Form.Item
                name="startingPoint"
                label="Starting Point"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Starting Point",
                  },
                ]}
              >
                <Input placeholder="Enter Starting Point " />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={20}>
            <Col span={24}>
              <Form.Item
                name="availability"
                label="Availability"
                rules={[{ required: true, message: "Please Select Date" }]}
              >
                <DatePicker multiple maxTagCount="responsive" disabledDate={date => date < minDate || date > maxDate} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={20}>
            <Col xs={24} sm={12}>
              <Form.Item
                name="destination"
                label="Select Destination"
                rules={[
                  {
                    required: true,
                    message: "Please select destination",
                  },
                ]}
              >
                <Select
                  showSearch
                  style={{ width: "100%" }}
                  placeholder="Select Destination"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0)
                  }
                  filterSort={(optionA, optionB) =>
                    (optionA?.children ?? '').toLowerCase().localeCompare((optionB?.children ?? '').toLowerCase())
                  }
                >
                  {destinationList?.filter(destination => destination.isPublished).map(destination => (
                    <Select.Option key={destination.id} value={destination.name}>{destination.name}</Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item
                name="activityType"
                label="Select Activity Type"
                rules={[
                  {
                    required: true,
                    message: "Please select activity type",
                  },
                ]}
              >
                <Select
                  showSearch
                  style={{ width: "100%" }}
                  placeholder="Select Activity Type"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0)
                  }
                  filterSort={(optionA, optionB) =>
                    (optionA?.children ?? "")
                      .toLowerCase()
                      .localeCompare((optionB?.children ?? "").toLowerCase())
                  }
                >
                  {activityList?.filter(activity => activity.isPublished).map(activity => (
                    <Select.Option key={activity.id} value={activity.name}>{activity.name}</Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={20}>
            <Col xs={24} sm={12}>
              <Form.Item
                name="tripType"
                label="Select Trip Type"
                rules={[
                  {
                    required: true,
                    message: "Please select Trip Type",
                  },
                ]}
              >
                <Select
                  showSearch
                  style={{ width: "100%" }}
                  placeholder="Select Trip Type"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0)
                  }
                  filterSort={(optionA, optionB) =>
                    (optionA?.children ?? "")
                      .toLowerCase()
                      .localeCompare((optionB?.children ?? "").toLowerCase())
                  }
                >
                  {tripTypeList?.filter(tripType => tripType.isPublished).map(tripType => (
                    <Select.Option key={tripType.id} value={tripType.name}>{tripType.name}</Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item
                name="destinationImgFile"
                label="Trip Image Or Video"
                rules={[
                  {
                    required: destinationImages.length === 0,
                    message: "Add atleast one trip image/video"
                  },
                ]}
              >
                <Upload {...destinationImagesProps} >
                  <Button icon={<UploadOutlined />}>Click to Upload</Button>
                </Upload>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={20}>
            <Col xs={24} sm={12}>
              <Form.Item
                name="coverPhotoFile"
                label="Cover Photo"
                rules={[
                  {
                    required: coverPhoto.length === 0,
                    message: "please Add cover photo"
                  },
                ]}
              >
                <Upload {...coverPhotoProps}>
                  <Button icon={<UploadOutlined />}>Click to Upload</Button>
                </Upload>
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item
                name="galleryImgFile"
                label="Gallery Images"
                rules={[
                  {
                    required: galleryImages.length === 0,
                    message: "Add atleast one gallery image",
                  },
                ]}
              >
                <Upload {...galleryImagesProps} >
                  <Button icon={<UploadOutlined />}>Click to Upload</Button>
                </Upload>
              </Form.Item>
            </Col>

          </Row>
          <Row gutter={20}>
            <Col span={12}>
              <Form.Item
                name="originalPrice"
                label="Original Price"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Original Price",
                  },
                ]}
              >
                <InputNumber
                  onChange={(value) => onOriginalPriceChange(form, value)}
                  placeholder="Enter The Original Price"
                  type="number"
                  controls={false}
                  style={{ width: '100%' }}
                  min={0}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="tokenPrice"
                label="Token Price"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Token Price",
                  },
                ]}
              >
                <InputNumber placeholder="Enter Token Price"
                  type="number"
                  controls={false}
                  style={{ width: '100%' }}
                  min={0}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={20}>
            <Col xs={24} sm={12}>
              <Form.Item
                name="sellingPrice"
                label="Selling Price(inclusive.gst)"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Selling Price",
                  },
                ]}
              >
                <InputNumber
                  type="number"
                  onChange={(value) => onSellingPriceChange(form, value)}
                  placeholder="Enter Selling Price"
                  controls={false}
                  style={{ width: '100%' }}
                  min={0}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item
                name="discountPercent"
                label="Discount"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <InputNumber
                  type="number"
                  disabled
                  controls={false}
                  style={{ width: '100%' }}

                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={20}>
            <Col span={24}>
              <Form.Item
                name="overview"
                label="Overview"
                rules={[
                  {
                    required: true,
                    message: "Please Enter The Overview",
                  },
                ]}
              >
                <TextArea
                  placeholder="Enter the Overview"
                  rows={4}
                  maxLength={1000}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={20}>
            <Col xs={24} sm={12}>
              <Form.Item
                name="region"
                label="Region"
                rules={[
                  {
                    required: true,
                    message: "Please Enter The Region",
                  },
                ]}
              >
                <Input placeholder="Enter The Region" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item
                name="feature"
                label="Select Featured"
                rules={[
                  {
                    required: true,
                    message: "Please select Feature",
                  },
                ]}
              >
                <Select
                  style={{ width: "100%" }}
                  placeholder="Select Featured"
                >
                  <Select.Option value="yes">Yes</Select.Option>
                  <Select.Option value="no">No</Select.Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={20}>
            <Col span={12}>
              <Form.Item
                name="altitude"
                label="Altitude"
                rules={[
                  {
                    required: true,
                    message: "Please Enter The Altitude",
                  },
                ]}
              >
                <Input placeholder="Enter The Altitude" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="trekking"
                label="Trekking"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Trekking",
                  },
                ]}
              >
                <Input placeholder="Enter Trekking" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={20}>
            <Col span={12}>
              <Form.Item
                name="includes"
                label="Includes"
                rules={[
                  {
                    required: true,
                    message: "Please Enter the Includes",
                  },
                ]}
              >
                <Input placeholder="Enter The Includes" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="duration"
                label="Duration"
                rules={[
                  {
                    required: true,
                    message: "Please Enter The Duration",
                  },
                ]}
              >
                <Input placeholder="Enter The Duration" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={20}>
            <Col span={12}>
              <Form.Item
                name="language"
                label="Language"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Language",
                  },
                ]}
              >
                <Input placeholder="Enter The Languages" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="meals"
                label="Meals"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Meals",
                  },
                ]}
              >
                <Input placeholder="Enter Meals" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={20}>
            <Col span={12}>
              <Form.Item
                name="fitnessLevel"
                label="Fitness Level"
                rules={[
                  {
                    required: true,
                    message: "Please Enter The Fitness Level",
                  },
                ]}
              >
                <Input placeholder="Enter The Fitness Level" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="grades"
                label="Grades"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Grades",
                  },
                ]}
              >
                <Input placeholder="Enter Grades" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={20}>
            <h1 style={{ marginLeft: '8px', marginBottom: "8px" }}>Itinerary</h1>
            <Col span={24}>
              <Form.List name="itinerary" initialValue={[{}]}>
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name, ...restField }) => (
                      <Card key={key} style={{ marginBottom: 8 }}>

                        <Form.Item
                          {...restField}
                          name={[name, 'day']}
                          label="Day"
                          style={{ marginBottom: "15px" }}
                          rules={[
                            {
                              required: true,
                              message: 'Missing day name',
                            },
                          ]}
                        >
                          <Input placeholder="Enter Day" />
                        </Form.Item>
                        <Form.Item
                          {...restField}
                          name={[name, 'heading']}
                          label="Heading"
                          style={{ marginBottom: "15px" }}
                          rules={[
                            {
                              required: true,
                              message: 'Missing heading',
                            },
                          ]}
                        >
                          <Input placeholder="Enter Heading" />
                        </Form.Item>
                        <Form.Item
                          {...restField}
                          name={[name, 'description']}
                          label="Description"
                          style={{ marginBottom: 0 }}
                          rules={[
                            {
                              required: true,
                              message: 'Missing description',
                            },
                          ]}
                        >
                          <Input.TextArea rows={4} placeholder="Enter description" />
                        </Form.Item>
                        {fields.length > 1 ? (
                          <MinusCircleOutlined
                            onClick={() => remove(name)}
                            style={{ position: 'absolute', right: '9px', top: '8px' }}
                          />
                        ) : null}
                      </Card>
                    ))}
                    <Form.Item>
                      <Button
                        type="primary"
                        onClick={() => add()}
                        style={{ backgroundColor: "#324966", width: '100%' }}
                        icon={<PlusOutlined />}
                      >
                        Add Itinerary Field
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form.List>

            </Col>
          </Row>
          <Row gutter={20} >
            <h1 style={{ marginLeft: '8px', marginBottom: "8px" }}>Inclusions</h1>
            <Col span={24}>
              <Form.List
                name="inclusions"
                initialValue={[""]}
              >
                {(fields, { add, remove }, { errors }) => (
                  <>
                    {fields.map((field, index) => (
                      <Form.Item
                        required={false}
                        key={field.key}
                        style={{ marginBottom: '30px' }}
                      >
                        <Form.Item
                          {...field}
                          validateTrigger={['onChange', 'onBlur']}
                          rules={[
                            {
                              required: true,
                              whitespace: true,
                              message: "Please Enter Inclusion",
                            },
                          ]}
                          noStyle
                        >
                          <Input
                            placeholder="Enter Inclusion"
                            style={{
                              width: '96%',
                            }}
                          />
                        </Form.Item>
                        {fields.length > 1 ? (
                          <MinusCircleOutlined
                            className="dynamic-delete-button"
                            onClick={() => remove(field.name)}
                            style={{ marginLeft: "7px" }}
                          />
                        ) : null}
                      </Form.Item>
                    ))}
                    <Form.Item>
                      <Button
                        type="primary"
                        onClick={() => add()}
                        style={{ backgroundColor: "#324966", width: '100%' }}
                        icon={<PlusOutlined />}
                      >
                        Add More Inclusions
                      </Button>
                      <Form.ErrorList errors={errors} />
                    </Form.Item>
                  </>
                )}
              </Form.List>
            </Col>
          </Row>
          <Row gutter={20} >
            <h1 style={{ marginLeft: '8px', marginBottom: "8px" }}>Exclusions</h1>
            <Col span={24}>
              <Form.List
                name="exclusions"
                initialValue={[""]}
              >
                {(fields, { add, remove }, { errors }) => (
                  <>
                    {fields.map((field, index) => (
                      <Form.Item
                        required={false}
                        key={field.key}
                        style={{ marginBottom: '30px' }}
                      >
                        <Form.Item
                          {...field}
                          validateTrigger={['onChange', 'onBlur']}
                          rules={[
                            {
                              required: true,
                              whitespace: true,
                              message: "Please Enter Exclusions",
                            },
                          ]}
                          noStyle
                        >
                          <Input
                            placeholder="Enter Exclusions"
                            style={{
                              width: '96%',
                            }}
                          />
                        </Form.Item>
                        {fields.length > 1 ? (
                          <MinusCircleOutlined
                            className="dynamic-delete-button"
                            onClick={() => remove(field.name)}
                            style={{ marginLeft: "7px" }}
                          />
                        ) : null}
                      </Form.Item>
                    ))}
                    <Form.Item>
                      <Button
                        type="primary"
                        onClick={() => add()}
                        style={{ backgroundColor: "#324966", width: '100%' }}
                        icon={<PlusOutlined />}
                      >
                        Add More Exclusions
                      </Button>
                      <Form.ErrorList errors={errors} />
                    </Form.Item>
                  </>
                )}
              </Form.List>
            </Col>
          </Row>
          <Row gutter={20} >
            <h1 style={{ marginLeft: '8px', marginBottom: "8px" }}>Fitness Advisory</h1>
            <Col span={24}>
              <Form.List
                name="fitnessAdvisory"
                initialValue={[""]}
              >
                {(fields, { add, remove }, { errors }) => (
                  <>
                    {fields.map((field, index) => (
                      <Form.Item
                        required={false}
                        key={field.key}
                        style={{ marginBottom: '30px' }}
                      >
                        <Form.Item
                          {...field}
                          validateTrigger={['onChange', 'onBlur']}
                          rules={[
                            {
                              required: true,
                              whitespace: true,
                              message: "Please Enter Fitness Advisory",
                            },
                          ]}
                          noStyle
                        >
                          <Input
                            placeholder="Enter Fitness Advisory"
                            style={{
                              width: '96%',
                            }}
                          />
                        </Form.Item>
                        {fields.length > 1 ? (
                          <MinusCircleOutlined
                            className="dynamic-delete-button"
                            onClick={() => remove(field.name)}
                            style={{ marginLeft: "7px" }}
                          />
                        ) : null}
                      </Form.Item>
                    ))}
                    <Form.Item>
                      <Button
                        type="primary"
                        onClick={() => add()}
                        style={{ backgroundColor: "#324966", width: '100%' }}
                        icon={<PlusOutlined />}
                      >
                        Add More Fitness Advisory
                      </Button>
                      <Form.ErrorList errors={errors} />
                    </Form.Item>
                  </>
                )}
              </Form.List>
            </Col>
          </Row>
          <Row gutter={20} >
            <h1 style={{ marginLeft: '8px', marginBottom: "8px" }}>Documents</h1>
            <Col span={24}>
              <Form.List
                name="documents"
                initialValue={[""]}
              >
                {(fields, { add, remove }, { errors }) => (
                  <>
                    {fields.map((field, index) => (
                      <Form.Item
                        required={false}
                        key={field.key}
                        style={{ marginBottom: '30px' }}
                      >
                        <Form.Item
                          {...field}
                          validateTrigger={['onChange', 'onBlur']}
                          rules={[
                            {
                              required: true,
                              whitespace: true,
                              message: "Please add documents here",
                            },
                          ]}
                          noStyle
                        >
                          <Input
                            placeholder="Enter Documents"
                            style={{
                              width: '96%',
                            }}
                          />
                        </Form.Item>
                        {fields.length > 1 ? (
                          <MinusCircleOutlined
                            className="dynamic-delete-button"
                            onClick={() => remove(field.name)}
                            style={{ marginLeft: "7px" }}
                          />
                        ) : null}
                      </Form.Item>
                    ))}
                    <Form.Item>
                      <Button
                        type="primary"
                        onClick={() => add()}
                        style={{ backgroundColor: "#324966", width: '100%' }}
                        icon={<PlusOutlined />}
                      >
                        Add More Documents
                      </Button>
                      <Form.ErrorList errors={errors} />
                    </Form.Item>
                  </>
                )}
              </Form.List>
            </Col>
          </Row>
          <Row gutter={20}>
            <h1 style={{ marginLeft: '8px', marginBottom: "8px" }}>FAQ's</h1>
            <Col span={24}>
              <Form.List name="faq" initialValue={[{}]}>
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name, ...restField }) => (
                      <Card key={key} style={{ marginBottom: 8 }}>

                        <Form.Item
                          {...restField}
                          name={[name, 'question']}
                          label="Question"
                          style={{ marginBottom: "15px", width: "100%" }}
                          rules={[
                            {
                              required: true,
                              message: 'Missing Question',
                            },
                          ]}
                        >
                          <Input placeholder="Enter Question" />
                        </Form.Item>
                        <Form.Item
                          {...restField}
                          name={[name, 'answer']}
                          label="Answer"
                          style={{ marginBottom: "15px", width: "100%" }}
                          rules={[
                            {
                              required: true,
                              message: 'Missing Answer',
                            },
                          ]}
                        >
                          <Input placeholder="Enter Answer" />
                        </Form.Item>

                        {fields.length > 1 ? (
                          <MinusCircleOutlined
                            onClick={() => remove(name)}
                            style={{ position: 'absolute', right: '9px', top: '8px' }}
                          />
                        ) : null}
                      </Card>
                    ))}
                    <Form.Item>
                      <Button
                        type="primary"
                        onClick={() => add()}
                        style={{ backgroundColor: "#324966", width: '100%' }}
                        icon={<PlusOutlined />}
                      >
                        Add More FAQ's
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form.List>
            </Col>
          </Row>
          <Row gutter={20} >
            <h1 style={{ marginLeft: '8px', marginBottom: "8px" }}>Things To Carry</h1>
            <Col span={24}>
              <Form.List
                name="thingsToCarry"
                initialValue={[""]}
              >
                {(fields, { add, remove }, { errors }) => (
                  <>
                    {fields.map((field, index) => (
                      <Form.Item
                        required={false}
                        key={field.key}
                        style={{ marginBottom: '30px' }}
                      >
                        <Form.Item
                          {...field}
                          validateTrigger={['onChange', 'onBlur']}
                          rules={[
                            {
                              required: true,
                              whitespace: true,
                              message: "Please enter things to carry",
                            },
                          ]}
                          noStyle
                        >
                          <Input
                            placeholder="Enter Things To Carry"
                            style={{
                              width: '96%',
                            }}
                          />
                        </Form.Item>
                        {fields.length > 1 ? (
                          <MinusCircleOutlined
                            className="dynamic-delete-button"
                            onClick={() => remove(field.name)}
                            style={{ marginLeft: "7px" }}
                          />
                        ) : null}
                      </Form.Item>
                    ))}
                    <Form.Item>
                      <Button
                        type="primary"
                        onClick={() => add()}
                        style={{ backgroundColor: "#324966", width: '100%' }}
                        icon={<PlusOutlined />}
                      >
                        Add More Things To Carry
                      </Button>
                      <Form.ErrorList errors={errors} />
                    </Form.Item>
                  </>
                )}
              </Form.List>
            </Col>
          </Row>
          <Row gutter={20} style={{ marginTop: "2rem" }}>
            <Col span={24}>
              <Form.Item
                wrapperCol={{
                  offset: 8,
                  span: 16,
                }}
              >
                <Space>
                  <Button onClick={onClose} danger style={{ width: "187px" }}>
                    Cancel
                  </Button>
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{ width: "200px", backgroundColor: "#324966" }}
                  >
                    {selectedTrips ? "Update" : "Add Trips"}
                  </Button>
                </Space>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Drawer>
    </>
  );
};
export default AddTrips;
