import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Drawer,
  Form,
  Input,
  Row,
  Space,
  Switch,
  // Select
} from "antd";
import { useForm } from "antd/es/form/Form";
import { createTripsType, updateTripsType } from "../api/tripsType";

const AddTripType = ({ isDrawerOpen, setIsDrawerOpen, selectedTripType, onTripTypeUpdated }) => {
  const [open, setOpen] = useState(isDrawerOpen);
  const [form] = useForm();

  if (selectedTripType) {
    form.setFieldsValue(selectedTripType);
  } else {
    form.resetFields();
  }
  const onFinish = async (formData) => {
    if (selectedTripType) {
      const id = selectedTripType.id;
      await updateTripsType(id, formData);
    } else {
      await createTripsType(formData)
    }
    setIsDrawerOpen(false);
    onTripTypeUpdated();
    console.log("Success:", formData);
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  const onClose = () => {
    setOpen(false);
    setIsDrawerOpen(false);
  };
  // const handleChange = (value) => {
  //   console.log(`selected ${value}`);
  // };
  useEffect(() => {
    setOpen(isDrawerOpen);
  }, [isDrawerOpen]);
  return (
    <>
      <Drawer
        title={
          <div>
            <Card.Meta
              title={
                <span style={{ marginBottom: "0" }}>{`${selectedTripType ? "Update" : "Add"} Trip Types`}</span>
              }
              description={
                <span style={{ fontWeight: "400", color: "#080305" }}>
                  {`${selectedTripType ? "Update" : "Add"} your trip type and necessary information from here`}
                </span>
              }
            />
          </div>
        }
        width={500}
        onClose={onClose}
        open={open}
        styles={{
          body: {
            paddingBottom: 80,
          },
        }}
      >
        <Form
          form={form}
          layout="vertical"
          requiredMark={false}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Row gutter={20}>
            <Col span={24}>
              <Form.Item
                name="name"
                label="Trip Type"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Trip Type",
                  },
                ]}
              >
                <Input placeholder="Name of Trip Type" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={20}>
            <Col span={12}>
              <Form.Item label="Published"></Form.Item>
            </Col>
            <Col span={12}>
              <Row gutter={20}>
                <Col span={12}></Col>
                <Col span={12}>
                  <Form.Item name="isPublished" label="" valuePropName="checked">
                    <Switch />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24} style={{ textAlign: "center" }}>
              <Form.Item
                wrapperCol={{
                  offset: 12,
                  span: 16,
                }}
                style={{ marginTop: 0 }}
              >
                <Space>
                  <Button danger onClick={onClose}>
                    Cancel
                  </Button>
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{ backgroundColor: "#324966" }}
                  >
                    {selectedTripType ? "Update" : "Save"}
                  </Button>
                </Space>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Drawer>
    </>
  );
};
export default AddTripType;
