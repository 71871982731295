import React, { useEffect, useState } from "react";
import Graph from "./Graph";

const SparkChart = ({color, data}) => {
  const [xAxis, setXAxis] = useState([]);
  const [yAxis, setYAxis] = useState([]);
  const graphData = {
    options: {
      chart: {
        type: 'area',
        height: 100,
        sparkline: {
          enabled: true
        },
      },
      stroke: {
        curve: 'smooth',
        width: 2,
      },
      fill: {
        opacity: 1,
      },
      yaxis: {
        show: false,
      },
      // Other options...
      xaxis: {
        categories: xAxis, // Using state values for xAxis
      },
    },
    series: [
      {
        name: "Sales",
        data: yAxis, // Using state values for yAxis
      },
    ],
  };
  function getXAndYAxis(salesByDay) {
    const xAxis = Object.keys(salesByDay); // Extracting keys as x-axis values
    const yAxis = Object.values(salesByDay); // Extracting values as y-axis values
  
    return { xAxis, yAxis };
  }
  useEffect(() => {
    const { xAxis: newXAxis, yAxis: newYAxis } = getXAndYAxis(data);    
    // Set state with the values
    setXAxis(newXAxis);
    setYAxis(newYAxis);
  }, [data])

  return (
    <div>
      <Graph
        graphData={graphData}
        type="line"
        height={70}
      />
    </div>
  );
};

export default SparkChart;
