import bookingApiService from "./bookingApiService";

// GET All product
export const getAllBooking = async (filters) => {
  if (filters !== null) {
    return bookingApiService.get(`/booking?userName=${filters.customerName}&paymentStatus=${filters.patmentStatus}&bookingStatus=${filters.bookingStatus}`);
  } else {
    return bookingApiService.get("/booking");
  }
};

export const getDashboardData = async () => {
  return bookingApiService.get("/booking/dashboard-orders");
};

export const updateStatus = async (body) => {
  try {
    const response = await bookingApiService.post(`/order/status-update`, body);
    return response;
  } catch (error) {
    console.error("Failed to update status");
    return [];
  }
};

export const updateDemoStatus = async (body) => {
  try {
    const response = await bookingApiService.post(
      `/demo/update-status/${body.id}`,
      body
    );
    return response.data;
  } catch (error) {
    console.error("Failed to update status");
    return [];
  }
};

export const getAllqutations = async () => {
  try {
    const response = await bookingApiService.get(`/demo/quotation/get-all`);
    return response.data.data;
  } catch (error) {
    console.error("Failed to update status");
    return [];
  }
};

export const updateQutations = async (payload) => {
  try {
    const response = await bookingApiService.patch(
      `/demo/quotation/update-status/${payload.id}`,
      payload
    );
    return response.data.data;
  } catch (error) {
    console.error("Failed to update status");
    return [];
  }
};
