export const countries = [
  {
    label: "Nepal",
    value: "nepal",
    states: [
      { label: "Province No. 1", value: "province_1" },
      { label: "Province No. 2", value: "province_2" },
      { label: "Bagmati Province", value: "bagmati_province" },
      { label: "Gandaki Province", value: "gandaki_province" },
      { label: "Lumbini Province", value: "lumbini_province" },
      { label: "Karnali Province", value: "karnali_province" },
      { label: "Sudurpashchim Province", value: "sudurpashchim_province" },
    ],
  },
  {
    label: "Sri Lanka",
    value: "srilanka",
    states: [
      { label: "Western Province", value: "western_province" },
      { label: "Central Province", value: "central_province" },
      { label: "Southern Province", value: "southern_province" },
      { label: "Northern Province", value: "northern_province" },
      { label: "Eastern Province", value: "eastern_province" },
      { label: "North Western Province", value: "north_western_province" },
      { label: "North Central Province", value: "north_central_province" },
      { label: "Uva Province", value: "uva_province" },
      { label: "Sabaragamuwa Province", value: "sabaragamuwa_province" },
    ],
  },
  {
    label: "Bhutan",
    value: "bhutan",
    states: [
      { label: "Eastern Bhutan", value: "eastern_bhutan" },
      { label: "Western Bhutan", value: "western_bhutan" },
      { label: "Central Bhutan", value: "central_bhutan" },
      { label: "Southern Bhutan", value: "southern_bhutan" },
    ],
  },
  {
    label: "Indonesia",
    value: "indonesia",
    states: [
      { label: "Java", value: "java" },
      { label: "Sumatra", value: "sumatra" },
      { label: "Kalimantan", value: "kalimantan" },
      { label: "Sulawesi", value: "sulawesi" },
      { label: "Papua", value: "papua" },
      { label: "Bali", value: "bali" },
      { label: "Nusa Tenggara", value: "nusa_tenggara" },
      { label: "Maluku", value: "maluku" },
    ],
  },
  {
    label: "Singapore",
    value: "singapore",
    states: [
      { label: "Central Region", value: "central_region" },
      { label: "East Region", value: "east_region" },
      { label: "North Region", value: "north_region" },
      { label: "North-East Region", value: "north_east_region" },
      { label: "West Region", value: "west_region" },
    ],
  },
  {
    label: "India",
    value: "india",
    states: [
      { label: "Andhra Pradesh", value: "andhra_pradesh" },
      { label: "Arunachal Pradesh", value: "arunachal_pradesh" },
      { label: "Assam", value: "assam" },
      { label: "Bihar", value: "bihar" },
      { label: "Chhattisgarh", value: "chhattisgarh" },
      { label: "Goa", value: "goa" },
      { label: "Gujarat", value: "gujarat" },
      { label: "Haryana", value: "haryana" },
      { label: "Himachal Pradesh", value: "himachal_pradesh" },
      { label: "Jharkhand", value: "jharkhand" },
      { label: "Karnataka", value: "karnataka" },
      { label: "Kerala", value: "kerala" },
      { label: "Madhya Pradesh", value: "madhya_pradesh" },
      { label: "Maharashtra", value: "maharashtra" },
      { label: "Manipur", value: "manipur" },
      { label: "Meghalaya", value: "meghalaya" },
      { label: "Mizoram", value: "mizoram" },
      { label: "Nagaland", value: "nagaland" },
      { label: "Odisha", value: "odisha" },
      { label: "Punjab", value: "punjab" },
      { label: "Rajasthan", value: "rajasthan" },
      { label: "Sikkim", value: "sikkim" },
      { label: "Tamil Nadu", value: "tamil_nadu" },
      { label: "Telangana", value: "telangana" },
      { label: "Tripura", value: "tripura" },
      { label: "Uttar Pradesh", value: "uttar_pradesh" },
      { label: "Uttarakhand", value: "uttarakhand" },
      { label: "West Bengal", value: "west_bengal" },
      { label: "Andaman and Nicobar Island", value: "andaman_nicobar" },
      { label: "Chandigarh", value: "chandigarh" },
      {
        label: "Dadra and Nagar Haveli and Daman and Diu",
        value: "dadra_nagar_haveli_daman_diu",
      },
      { label: "Delhi", value: "delhi" },
      { label: "Ladakh", value: "ladakh" },
      { label: "Lakshadweep", value: "lakshadweep" },
      { label: "Puducherry", value: "puducherry" },
      { label: "Jammu and Kashmir", value: "jammu_kashmir" },
    ],
  },
];