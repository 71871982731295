import AppRoutes from "../Routes";

function PageContent() {
  return (
    <div className="PageContent">
          <AppRoutes />
    </div>
  );
}
export default PageContent;
