import React from "react";
import { ReactSVG } from "react-svg";
import { DownArrow } from "../Icons";
import { Dropdown, Space } from "antd";
import Cookies from "universal-cookie";
import { useNavigate } from "react-router-dom";

const Profile = () => {
  const cookie = new Cookies();
  const navigate = useNavigate();
  const items = [
    {
      key: "1",
      label: (
        <div className="user-dropdown">
          <div onClick={() => handleLogout()}>
            <p>Logout</p>
          </div>
        </div>
      ),
    },
  ];

  const handleLogout = () => {
    cookie.remove("token");
    navigate("/login");
  };

  return (
    <div className="headerProfile">
      <Dropdown menu={{ items }}>
        <a onClick={(e) => e.preventDefault()}>
          <Space>
            <div className="headerAvater">
              <img
                src={`https://images.pexels.com/photos/762020/pexels-photo-762020.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1`}
              />
            </div>
            <div className="avatarName">Admin</div>
            <ReactSVG src={DownArrow} className="profileDownArrow" />{" "}
          </Space>
        </a>
      </Dropdown>
    </div>
  );
};

export default Profile;
