export const onSellingPriceChange = (form, value) => {
  const originPrice = form.getFieldValue("originalPrice");
  if (originPrice) {
    const sellingPrice = parseFloat(value);
    const calculatedDiscount = ((originPrice - sellingPrice) / originPrice) * 100;
    const sanitizedDiscount = sanitizeDiscount(calculatedDiscount)
    form.setFieldsValue({ discountPercent: sanitizedDiscount });
  }
};

export const onOriginalPriceChange = (form, value) => {
  const sellingPrice = form.getFieldValue("sellingPrice");
  if (sellingPrice) {
    const originalPrice = parseFloat(value);
    const calculatedDiscount = ((originalPrice - sellingPrice) / originalPrice) * 100;
    const sanitizedDiscount = sanitizeDiscount(calculatedDiscount)
    form.setFieldsValue({ discountPercent: sanitizedDiscount });
  }
};

 function sanitizeDiscount(str) {
  const num = parseFloat(str);
  let sanitizedDiscount = Math.floor(Number(num));
  if (sanitizedDiscount < 0) {
    sanitizedDiscount = 0;
  } else if (sanitizedDiscount > 100) {
    sanitizedDiscount = 100;
  }

  return sanitizedDiscount;
}
