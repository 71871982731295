import React from "react";
import { ReactSVG } from "react-svg";
import { LogoPng } from "../Icons";
const Logo = () => {
  return (
    <div className="mainLogo">
      {/* <ReactSVG src={LogoPng} /> */}
      <img
        alt="logo"
        src={LogoPng}
        style={{ width: "100%" }}
      />
    </div>
  );
};

export default Logo;
