import { DownOutlined, SearchOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  DatePicker,
  Dropdown,
  Input,
  Row,
  Space,
  Menu,
} from "antd";
import { debounce } from "../Utils/debounce";
import { useState } from "react";
import BookingTable from "../Components/Table/BookingTable";
import React from "react";
const buttonStyle = {
  minWidth: "110px",
  height: "40px",
  padding: "20px, 0px, 20px, 0px",
  borderRadius: "6px",
  marginRight: "10px",
};

const BookingsPage = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [dateRange, setDateRange] = useState(null);
  const [paymentStatus, setPaymentStatus] = useState("");
  const [bookingStatus, setBookingStatus] = useState("");

  const handlePaymentStatusMenuClick = (e) => {
    e.key === "reset"?setPaymentStatus(""):setPaymentStatus(e.key)
  };
  const handleBookingStatusMenuClick = (e) => {
    e.key === "reset"?setBookingStatus(""):setBookingStatus(e.key);
  };

  const handleOrderLimitsMenuClick = (e) => {
    console.log("Order Limits Menu click", e);
  };

  const [searchValue, setSearchValue] = useState("");

  const handleSearch = (e) => {
    setSearchValue(e.target.value);
  };

  const handleDateRangeChange = (dates) => {
    setDateRange(dates);
  };

  const bookingStatusMenuProps = {
    items: [

      {
        label: "Consent Pending",
        key: "consentPending",
      },
      {
        label: "Active",
        key: "active",
      },
      {
        label: "Completed",
        key: "completed",
      },
      {
        label: "Reset",
        key: "reset",
      },
    ],
    onClick: handleBookingStatusMenuClick,
    selectable: true,
  };

  const orderLimitsMenuProps = {
    items: [
      {
        label: "Last 5 Days Orders",
        key: "1",
      },
      {
        label: "Last 7 Days Orders",
        key: "2",
      },
      {
        label: "Last 15 Days Orders",
        key: "3",
      },
      {
        label: "Last 30 Days Orders",
        key: "4",
      },
    ],
    onClick: handleOrderLimitsMenuClick,
  };

  const paymentStatusMenuProps = {
    items: [
      {
        label: "Pending",
        key: "pending",
      },
      {
        label: "Inprogress",
        key: "inprogress",
      },
      {
        label: "Completed",
        key: "completed",
      },
      {
        label: "Failed",
        key: "failed",
      },
      {
        label: "Reset",
        key: "reset",
      },
    ],
    onClick: handlePaymentStatusMenuClick,
    selectable: true,
  };

  const exportItems = [
    {
      label: "Export to csv",
      key: "1",
    },
    {
      label: "Export to Json",
      key: "2",
    },
  ];
  return (
    <>
      <div>
        <Row gutter={16} style={{ marginTop: "20px" }}>
          <Col span={12}>
            {/* Content for the first column in the first row */}
            <Input
              size="large"
              placeholder="Search By Customer Name"
              suffix={<SearchOutlined />}
              onChange={debounce(handleSearch, 800)}
            />
          </Col>
          <Col span={12} style={{ textAlign: "right" }}>
            {/* Content for the second column in the first row */}
            <Space>
              <Dropdown
                menu={bookingStatusMenuProps}
              >
                <Button style={{ ...buttonStyle }}>

                  <Space>
                    Booking Status
                    <DownOutlined />
                  </Space>
                </Button>
              </Dropdown>
              {/* <Dropdown menu={orderLimitsMenuProps}>
                <Button style={buttonStyle}>
                  <Space>
                    Order Limits
                    <DownOutlined />
                  </Space>
                </Button>
              </Dropdown> */}
              <Dropdown
                menu={paymentStatusMenuProps}
              >
                <Button style={{ ...buttonStyle }}>

                  <Space>
                    Payment Status
                    <DownOutlined />
                  </Space>
                </Button>
              </Dropdown>
              {/* <Dropdown
                overlay={
                  <Menu onClick={handleMethodMenuClick}>
                    {paymentStatusMenuProps.items.map((item) => (
                      <Menu.Item key={item.key}>{item.label}</Menu.Item>
                    ))}
                  </Menu>
                }
              >

                <Button style={{ ...buttonStyle }}>
                  <Space>
                    Payment Status
                    <DownOutlined />
                  </Space>
                </Button>
              </Dropdown> */}
            </Space>
          </Col>
        </Row>
        {/* <Row gutter={16} style={{ marginTop: "20px" }}>
          <Col span={12}>
            <DatePicker.RangePicker
              size="large"
              style={{ width: "100%" }}
              onChange={handleDateRangeChange}
            />
          </Col>
        </Row> */}
        <Row gutter={16} style={{ marginTop: "20px" }}>
          <Col span={24}>
            <BookingTable
              searchValue={searchValue}
              dateRange={dateRange}
              paymentStatus={paymentStatus}
              bookingStatus={bookingStatus}
            />
          </Col>
        </Row>
      </div>
    </>
  );
};

export default BookingsPage;
