import axios from "axios";
import store from "../store"; // Import your Redux store
import Cookies from "universal-cookie";

const cookie = new Cookies();

const authApiService = axios.create({
  baseURL:
    process.env.NODE_ENV === "development"
      ? "https://authapi.travelpocket.in/v1/api"
      : "https://authapi.travelpocket.in/v1/api",
});

// Request interceptor to include auth token in specific requests
authApiService.interceptors.request.use(
  (config) => {
    // const authToken = store.getState().auth.authToken;
    // let authToken = localStorage.getItem("auth_token");
    const authToken = cookie.get("token");

    if (authToken) {
      // Check if the request URL requires authorization (e.g., cart-related endpoints)
      if (config.url.includes("/cart/") || config.url.includes("/auth")) {
        config.headers["Authorization"] = `Bearer ${authToken}`;
      }
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default authApiService;
