const INITIAL_STATE = {
  wish: [
    {
      name: "nifty 50",
      total: 19839.5,
      increase: 113.8,
    },
    {
      name: "banknifty",
      total: 19839.5,
      increase: 113.8,
    },
    {
      name: "radico kaithan",
      total: 19839.5,
      increase: 113.8,
    },
    {
      name: "NBCC(India",
      total: 19839.5,
      increase: 113.8,
    },
    {
      name: "zomato",
      total: 19839.5,
      increase: 113.8,
    },
    {
      name: "SBI Bank",
      total: 19839.5,
      increase: 113.8,
    },
    {
      name: "Punjab National Bank",
      total: 19839.5,
      increase: 113.8,
    },
    {
      name: "Zomato Ltd.",
      total: 19839.5,
      increase: 113.8,
    },
    {
      name: "Suzlon Energy Ltd.",
      total: 19839.5,
      increase: 113.8,
    },
  ],
  active: "dashboard",
};

const WishList = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "value":
      return {
        ...state,
      };

    default:
      return state;
  }
};

export default WishList;
