// import { useEffect, useState } from "react";
import {deleteTrips } from "../../api/trips";
import { Modal } from "antd";
import { deleteDestination } from "../../api/destination";
// import { deleteVendor } from "../../api/vendor";
import { deleteActivityType } from "../../api/activityType";
import { deleteTripsType } from "../../api/tripsType";

const DeleteModal = ({
  isProductDeleting,
  deletingObj,
  onItemDeleted,
  table,
  setIsProductDeleting
}) => {
  // const [isModalOpen, setIsModalOpen] = useState(isProductDeleting);
  // const showModal = () => {
  //   setIsModalOpen(true);
  // };
  const handleOk = async () => {
    if (table === "trips") {
      await deleteTrips(deletingObj.id);
    } else if (table === "destination") {
      await deleteDestination(deletingObj.id);
    } else if (table === "activityType") {
      await deleteActivityType(deletingObj.id);
    }else if (table === "tripType") {
      await deleteTripsType(deletingObj.id);
    }
    // else if (table === "vendors") {
    //   await deleteVendor(deletingObj.id);
    // }
    setIsProductDeleting(false);
    onItemDeleted(true);
  };
  const handleCancel = () => {
    setIsProductDeleting(false);
  };
  // useEffect(() => {
  //   setIsModalOpen(isProductDeleting);
  // }, [isProductDeleting]);
  
  return (
    <>
      <Modal
        title="Delete"
        open={isProductDeleting}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <p>Are you sure you want to delete?</p>
      </Modal>
    </>
  );
};
export default DeleteModal;
