import React, { useState, useEffect } from "react";
import { Button, Table } from "antd";
import { DeleteFilled, EditFilled, EyeOutlined } from "@ant-design/icons";
import DeleteModal from "../Modal/DeleteModal";

const VendorTable = ({
  allVendors,
  searchValue,
  fetchData,
  onVendorSelected,
  setIsViewMode,
}) => {
  const [isVendorDeleting, setIsVendorDeleting] = useState(false);
  const [vendorToDelete, setVendorToDelete] = useState(null);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [filteredVendors, setFilteredVendors] = useState([]);
  const [allCustomersFormatted, setAllVendorsFormatted] = useState([]);

  const handleEditClick = (record) => {
    let vendor = allVendors.filter((item) => item.id === record.id);
    onVendorSelected(vendor[0]);
    setIsViewMode(false);
  };

  const handleDeleteClick = (record) => {
    setIsVendorDeleting(true);
    setVendorToDelete(record);
  };

  const handleViewClick = (record) => {
    let vendor = allVendors.filter((item) => item.id === record.id);
    onVendorSelected(vendor[0]);
    setIsViewMode(true);
  };

  const sortByDate = (a, b) => {
    const datePartsA = a.joiningDate.split(" ");
    const datePartsB = b.joiningDate.split(" ");

    const yearA = parseInt(datePartsA[2]);
    const monthA = parseInt(datePartsA[0].toLowerCase() === "january" ? 0 : datePartsA[0].toLowerCase() === "february" ? 1 : NaN);
    const dayA = parseInt(datePartsA[1]);

    const yearB = parseInt(datePartsB[2]);
    const monthB = parseInt(datePartsB[0].toLowerCase() === "january" ? 0 : datePartsB[0].toLowerCase() === "february" ? 1 : NaN);
    const dayB = parseInt(datePartsB[1]);

    if (yearA !== yearB) {
      return yearA - yearB;
    } else if (monthA !== monthB) {
      return monthA - monthB;
    } else {
      return dayA - dayB;
    }
  };

  const columns = [
    {
      title: "JOINING DATE",
      dataIndex: "joiningDate",
      sorter: (a, b) => sortByDate(a, b),
    },
    {
      title: "NAME",
      dataIndex: "name",
      sorter: (a, b) => a.name.length - b.name.length,
    },
    {
      title: "EMAIL",
      dataIndex: "email",
    },
    {
      title: "PHONE",
      dataIndex: "phone",
    },
    {
      title: "ACTIONS",
      dataIndex: "action",
      render: (text, record) => {
        return (
          <>
            <Button
              icon={
                <EditFilled style={{ fontSize: "20px", color: "#04B2A9" }} />
              }
              style={{ margin: "0 5px" }}
              onClick={() => handleEditClick(record)}
            />
            <Button
              style={{ margin: "0 5px" }}
              icon={<DeleteFilled style={{ fontSize: "20px", color: "red" }} />}
              onClick={() => handleDeleteClick(record)}
            />
            <Button
              style={{ margin: "0 5px" }}
              icon={<EyeOutlined style={{ fontSize: "20px", color: "blue" }} />}
              onClick={() => handleViewClick(record)}
            />
          </>
        );
      },
    },
  ];

  const onItemDeleted = (isitemDeleted) => {
    setIsVendorDeleting(false);
    fetchData();
  };

  const formatDate = (date) => {
    const formattedDate = new Date(date);
    return formattedDate.toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  useEffect(() => {
    const filteredVendors = allCustomersFormatted.filter((orderObj) => {
      if (searchValue) {
        const searchLowerCase = searchValue.toLowerCase();
        return (
          (orderObj.name &&
            orderObj.name.toLowerCase().includes(searchLowerCase)) ||
          (orderObj.email &&
            orderObj.email.toLowerCase().includes(searchLowerCase)) ||
          (orderObj.phone &&
            orderObj.phone.toLowerCase().includes(searchLowerCase))
        );
      }
      return true;
    });

    setFilteredVendors(filteredVendors);
  }, [searchValue]);

  useEffect(() => {
    const formattedData = allVendors.map((customer, index) => ({
      id: customer.id,
      joiningDate: formatDate(customer.createdAt),
      name: customer.brandName,
      email: customer.email,
      phone: customer.mobile?.slice(2),
      action: "",
    }));
    setAllVendorsFormatted(formattedData);
    setFilteredVendors(formattedData);
  }, [allVendors]);

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const hasSelected = selectedRowKeys.length > 0;

  return (
    <div>
      <Table
        columns={columns}
        dataSource={filteredVendors}
        scroll={{ x: "max-content" }}
      />
      <DeleteModal
        isProductDeleting={isVendorDeleting}
        deletingObj={vendorToDelete}
        table={"vendors"}
        onItemDeleted={onItemDeleted}
      />
    </div>
  );
};

export default VendorTable;
