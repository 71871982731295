import {
  SearchOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Col, Input, Row } from "antd";
import { useState } from "react";
// import AddCategory from "../Components/AddDestination";
import CustomerTable from "../Components/Table/CustomerTable";
import { useEffect } from "react";
import { getAllCustomer } from "../api/customer";
const buttonStyle = {
  minWidth: "110px",
  height: "40px",
  padding: "20px, 0px, 20px, 0px",
  borderRadius: "6px",
  marginRight: "10px",
};

const CustomerPage = () => {
  const [allCustomers, setAllCustomers] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const handleMenuClick = (e) => {
    console.log("click", e);
  };
  const items = [
    {
      label: "1st menu item",
      key: "1",
      icon: <UserOutlined />,
    },
    {
      label: "2nd menu item",
      key: "2",
      icon: <UserOutlined />,
    },
    {
      label: "3rd menu item",
      key: "3",
      icon: <UserOutlined />,
      danger: true,
    },
    {
      label: "4rd menu item",
      key: "4",
      icon: <UserOutlined />,
      danger: true,
      disabled: true,
    },
  ];
  const handleSearch = (e) => {
    setSearchValue(e.target.value);
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getAllCustomer();
        if (response) {          
          setAllCustomers(response);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);
  return (
    <>
      <div>
        <Row gutter={16} style={{ marginTop: "20px" }}>
          <Col span={18}>
            {/* Content for the first column in the first row */}
            <Row gutter={16}>
              <Col span={12}>
                <Input
                  placeholder="Search by name/email/phone"
                  suffix={<SearchOutlined />}
                  onChange={handleSearch}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row gutter={16} style={{ marginTop: "20px" }}>
          <Col span={24}>
            <CustomerTable allCustomers={allCustomers} searchValue={searchValue} />
          </Col>
        </Row>
      </div>
    </>
  );
};

export default CustomerPage;
