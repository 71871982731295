import { combineReducers } from "redux";
import Menu from "./Menu";
import WishList from "./WishList";

const rootReducer = combineReducers({
  menu: Menu,
  wishLists: WishList,
});

export default rootReducer;
