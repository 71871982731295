import authApiService from "./authApiService";



// GET All Customer
export const getAllCustomer = async () => {
  try {
    const response = await authApiService.get("auth/get-admin-user-list");
    return response.data.data;
  } catch (error) {
    // Handle the error here, you can log it or perform other actions as needed
    console.error("Error fetching customer list:", error);
    return [];
  }
};
