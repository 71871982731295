import React, { useEffect, useState } from 'react';
import { Button, Switch, Table } from 'antd';
import { DeleteFilled, EditFilled } from '@ant-design/icons';
import DeleteModal from '../Modal/DeleteModal';
import { updateActivityType } from '../../api/activityType';

const ActivityTypeTable = ({ activityType, onActivityTypeSelected, onActivityTypeUpdated, searchText }) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [dataSource, setDataSource] = useState([]);
  const [isActivityTypeDeleting, setIsActivityTypeDeleting] = useState(false);
  const [activityTypeToDelete, setActivityTypeToDelete] = useState(null);
  const [filteredDataSource, setFilteredDataSource] = useState([]);

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const handleEditClick = (record) => {
    onActivityTypeSelected(record, 'edit');
  };

  const handleSwitchChange = async (checked, record) => {
    await updateActivityType(record.id, { isPublished: checked });
    onActivityTypeUpdated();
  };

  const handleDeleteClick = (record) => {
    setIsActivityTypeDeleting(true);
    setActivityTypeToDelete(record);
  };

  const onItemDeleted = (isItemDelted) => {
    if (isItemDelted) {
      onActivityTypeUpdated();
    }
  }

  const columns = [
    {
      title: 'S.No',
      dataIndex: 'sno',
      sorter: (a, b) => a.id > b.id ? 1 : (a.id < b.id ? -1 : 0),
    },
    {
      title: 'Name of Activity',
      dataIndex: 'name',
      sorter: (a, b) => a.name.length - b.name.length,
    },
    {
      title: 'Publish',
      dataIndex: 'isPublished',
      render: (active, record) => {
        return (
          <Switch
            checked={active}
            onChange={(checked) => handleSwitchChange(checked, record)}
          />
        );
      },
    },
    {
      title: 'Actions',
      dataIndex: 'action',
      render: (text, record) => {
        return (
          <>
            <Button
              icon={
                <EditFilled style={{ fontSize: "20px", color: "#324966" }} />
              }
              style={{ marginRight: '15px' }}
              onClick={() => handleEditClick(record)}
            />
            <Button
              icon={<DeleteFilled style={{ fontSize: "20px", color: "red" }} />}
              onClick={() => handleDeleteClick(record)}
            />
          </>
        );
      },
    },
  ];

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const getDataObject = (activityType) => {
    const dataObject = [];
    for (let i = 0; i < activityType.length; i++) {
      const activityData = activityType[i];
      const obj = {
        sno: i + 1,
        name: activityData.name,
        isPublished: activityData.isPublished,
        id: activityData.id
      }
      dataObject.push(obj);
    }
    setDataSource(dataObject)
    setFilteredDataSource(dataObject);
  }

  function filterActivityData(searchText) {
    if (dataSource.length && searchText) {
      const searchTextLower = searchText.toLowerCase();
      const filteredProducts = dataSource.filter((activity) => {
        for (const key in activity) {
          if (
            activity[key] &&
            activity[key].toString().toLowerCase().includes(searchTextLower)
          ) {
            return true;
          }
        }
        return false;
      });
      setFilteredDataSource(filteredProducts);
    } else {
      setFilteredDataSource(dataSource);
    }
  }

  useEffect(() => {
    getDataObject(activityType);
  }, [activityType])

  useEffect(() => {
    filterActivityData(searchText);
  }, [searchText]);

  return (
    <div>
      <Table rowSelection={rowSelection} columns={columns} dataSource={filteredDataSource} scroll={{ x: 'max-content' }} />
      <DeleteModal isProductDeleting={isActivityTypeDeleting} deletingObj={activityTypeToDelete} onItemDeleted={onItemDeleted} setIsProductDeleting={setIsActivityTypeDeleting} table={'activityType'} />
    </div>
  );
};
export default ActivityTypeTable;