import authApiService from "./authApiService";

// GET All Vendor
export const getAllVendor = async () => {
  try {
    const response = await authApiService.get("auth/get-vendor-list");
    return response.data.data;
  } catch (error) {
    // Handle the error here, you can log it or perform other actions as needed
    console.error("Error fetching vendor list:", error);
    return [];
  }
};

export const getAllBrands = async () => {
  try {
    const response = await authApiService.get("auth/brands");
    return response.data.data;
  } catch (error) {
    // Handle the error here, you can log it or perform other actions as needed
    console.error("Error fetching brand list:", error);
    return [];
  }
};

export const updateVendor = async (body) => {
  try {
    const response = await authApiService.patch(`/auth/vendor`, body);
    return response.data;
  } catch (error) {
    console.error("Error updating vendor:", error);
    return [];
  }
};

export const deleteVendor = async (id) => {
  try {
    const response = await authApiService.delete(`/auth/vendor/${id}`);
    return response.data.data;
  } catch (error) {
    console.error("Error deleting vendor ", error);
    return [];
  }
};

export const verifyVendor = async (id) => {
  try {
    const response = await authApiService.patch(`/auth/vendor/verify/${id}`);
    return response.data;
  } catch (error) {
    console.error("Error deleting vendor ", error);
    return [];
  }
};
