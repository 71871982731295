import React, { useEffect, useState } from "react";
import { Table, Select, Button, message } from "antd";
import { CSVLink } from "react-csv";
import { getAllBooking, updateStatus } from "../../api/booking";
import { getAllTrips } from "../../api/trips";
import Moment from 'react-moment';

const { Option } = Select;

const BookingTable = ({ searchValue, dateRange, paymentStatus, bookingStatus }) => {
  const [bookingData, setBookingData] = useState([]);
  const [isPageLoaded, setIsPageLoaded] = useState(false);
  const [csvData, setCsvData] = useState([]);
  const [filteredBookingData, setFilteredData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  // const updateOrderStatus = async (status, order) => {
  //   console.log(status, order);
  //   setIsLoading(order.id);
  //   try {
  //     const { data } = await updateStatus({ status, id: order.id });
  //     if (data) {
  //       console.log(data);
  //       message.success("Status updated successfully!");
  //       setIsLoading(false);
  //     }
  //   } catch (error) {
  //     setIsLoading(false);
  //     message.error("Failed to update status!");
  //   }
  // };
  const columns = [
    {
      title: "Date of Booking",
      dataIndex: "dateofBooking",
      key: "dateofBooking",
    },
    {
      title: "User Name",
      dataIndex: "userName",
      key: "userName",
    },
    {
      title: "Destination",
      dataIndex: "destination",
      key: "destination",
    },
    {
      title: "No of Visitors",
      dataIndex: "noOfTravelers",
      key: "noOfTravelers",
    },
    {
      title: "Trip",
      dataIndex: "trip",
      key: "trip",
    },
    {
      title: "Departure Date",
      dataIndex: "departureDate",
      key: "departureDate",
    },
    {
      title: "Consent",
      dataIndex: "consent",
      key: "consent",
    },
    {
      title: "Payment Status",
      dataIndex: "paymentStatus",
      key: "paymentStatus",
    },
    {
      title: "Booking Status",
      dataIndex: "bookingStatus",
      key: "bookingStatus",
    },
  ];

  const getBookings = async () => {
    try {
      const response = await getAllBooking(null);
      const trips = await getAllTrips();
      if (response && response.data) {
        const transformedData = response.data.response.map((booking, index) => {
          const tripData = trips.data.data.find((trip) => {
            return trip.id == booking.tripId
          });
          return {
            key: index.toString(),
            dateofBooking: new Date(booking.createdAt).toDateString(),
            userName: booking.name,
            destination: tripData ? tripData.destination : "",
            noOfTravelers: booking.noOfTravelers,
            trip: tripData ? tripData.tripTitle : "",
            departureDate: <Moment unix format="ddd, MMM Do YYYY, h:mm a">{booking.tripDate}</Moment>,
            consent: booking.bookingStatus === "consentPending" ? "notGiven" : "given",
            paymentStatus: booking.paymentStatus,
            bookingStatus: booking.bookingStatus,
          };
        });

        setBookingData(transformedData);
        setFilteredData(transformedData);
        setCsvData(transformedData);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        let filters = {
          customerName: searchValue,
          dateRange: dateRange,
          bookingStatus: bookingStatus,
          patmentStatus: paymentStatus,
        };
        const response = await getAllBooking(filters);
        const trips = await getAllTrips();
        if (response && response.data) {
          const transformedData = response.data.response.map((booking, index) => {
            const tripData = trips.data.data.find((trip) => {
              return trip.id == booking.tripId
            });
            return {
              key: index.toString(),
              dateofBooking: new Date(booking.createdAt).toDateString(),
              userName: booking.name,
              destination: tripData?.destination,
              noOfTravelers: booking.noOfTravelers,
              trip: tripData?.tripTitle,
              departureDate: <Moment unix format="ddd, MMM Do YYYY, h:mm a">{booking.tripDate}</Moment>,
              consent: booking.bookingStatus == "consentPending" ? "notGiven" : "given",
              paymentStatus: booking.paymentStatus,
              bookingStatus: booking.bookingStatus,
            };
          });

          setBookingData(transformedData);
          setFilteredData(transformedData);
          setCsvData(transformedData); // Set data for CSV download
          // const filteredBySearch = transformedData.filter((order) =>
          //   order.customerName
          //     ?.toLowerCase()
          //     .includes(searchValue.toLowerCase())
          // );

          // let filteredByDateRange = transformedData.filter((order) => {
          //   if (dateRange && dateRange.length === 2) {
          //     const orderDate = new Date(order.createdAt);
          //     return (
          //       orderDate >= dateRange[0].startOf("day") &&
          //       orderDate <= dateRange[1].endOf("day")
          //     );
          //   }
          //   return true;
          // });
          // if (paymentStatus) {
          //   filteredByDateRange = filteredByDateRange.filter(
          //     (order) =>
          //       order.methods.toLowerCase() === paymentStatus.toLowerCase()
          //   );
          // }
          // if (bookingStatus !== "") {
          //   filteredByDateRange = filteredByDateRange.filter(
          //     (order) =>
          //       order.status.toLowerCase() === bookingStatus.toLowerCase()
          //   );
          // }

          // setFilteredData(filteredByDateRange);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    // Fetch data when component mounts or when searchValue or dateRange changes
    if (isPageLoaded) {
      fetchData();
    }
  }, [searchValue, dateRange, paymentStatus, bookingStatus]);

  useEffect(() => {
    getBookings();
    setIsPageLoaded(true);
  }, []);

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginBottom: 16,
        }}
      >
        <CSVLink data={csvData} filename={"bookings.xls"} className="ant-btn">
          <Button>Download </Button>
        </CSVLink>
      </div>
      <Table
        columns={columns}
        dataSource={filteredBookingData}
        scroll={{ x: "max-content" }}
      />
    </>
  );
};

export default BookingTable;
