import React, { useEffect, useState } from 'react';
import { Button, Switch, Table } from 'antd';
import { DeleteFilled, EditFilled } from '@ant-design/icons';
import DeleteModal from '../Modal/DeleteModal';
import { updateTripsType } from '../../api/tripsType';

const TripTypeTable = ({ tripType, onTripTypeSelected, onTripTypeUpdated, searchText }) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [dataSource, setDataSource] = useState([]);
  const [isTripTypeDeleting, setIsTripTypeDeleting] = useState(false);
  const [tripTypeToDelete, setTripTypeToDelete] = useState(null);
  const [filteredDataSource, setFilteredDataSource] = useState([]);
  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const handleEditClick = (record) => {
    onTripTypeSelected(record, 'edit');
  };

  const handleSwitchChange = async (checked, record) => {
    await updateTripsType(record.id, { isPublished: checked });
    onTripTypeUpdated();
  };

  const handleDeleteClick = (record) => {
    setIsTripTypeDeleting(true);
    setTripTypeToDelete(record);
  };

  const onItemDeleted = (isItemDelted) => {
    if (isItemDelted) {
      onTripTypeUpdated();
    }
  }

  const columns = [
    {
      title: 'S.No',
      dataIndex: 'sno',
      sorter: (a, b) => a.id > b.id ? 1 : (a.id < b.id ? -1 : 0),
    },
    {
      title: 'Trip Types',
      dataIndex: 'name',
      sorter: (a, b) => a.name.length - b.name.length,
    },
    {
      title: 'Publish',
      dataIndex: 'isPublished',
      render: (active, record) => {
        return (
          <Switch
            checked={active}
            onChange={(checked) => handleSwitchChange(checked, record)}
          />
        );
      },
    },
    {
      title: 'Actions',
      dataIndex: 'action',
      render: (text, record) => {
        return (
          <>
            <Button
              icon={
                <EditFilled style={{ fontSize: "20px", color: "#324966" }} />
              }
              onClick={() => handleEditClick(record)}
              style={{ marginRight: '15px' }}

            />
            <Button
              icon={<DeleteFilled style={{ fontSize: "20px", color: "red" }} />}
              onClick={() => handleDeleteClick(record)}
            />
          </>
        );
      },
    },
  ];

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const getDataObject = (tripType) => {
    const dataObject = [];
    for (let i = 0; i < tripType.length; i++) {
      const tripTypeData = tripType[i];
      const obj = {
        sno: i + 1,
        name: tripTypeData.name,
        isPublished: tripTypeData.isPublished,
        id: tripTypeData.id,
      }
      dataObject.push(obj);
    }
    setDataSource(dataObject)
    setFilteredDataSource(dataObject);
  }

  function filterTripTypeData(searchText) {
    if (dataSource.length && searchText) {
      const searchTextLower = searchText.toLowerCase();
      const filteredProducts = dataSource.filter((tripType) => {
        for (const key in tripType) {
          if (
            tripType[key] &&
            tripType[key].toString().toLowerCase().includes(searchTextLower)
          ) {
            return true;
          }
        }
        return false;
      });
      setFilteredDataSource(filteredProducts);
    } else {
      setFilteredDataSource(dataSource);
    }
  }

  useEffect(() => {
    getDataObject(tripType);
  }, [tripType])

  useEffect(() => {
    filterTripTypeData(searchText);
  }, [searchText]);

  return (
    <div>
      <Table rowSelection={rowSelection} columns={columns} dataSource={filteredDataSource} scroll={{ x: 'max-content' }} />
      <DeleteModal isProductDeleting={isTripTypeDeleting} deletingObj={tripTypeToDelete} onItemDeleted={onItemDeleted} setIsProductDeleting={setIsTripTypeDeleting} table={'tripType'} />
    </div>
  );
};
export default TripTypeTable;