import {
  AppstoreOutlined,
  SettingOutlined,
  ShopOutlined,
  ShoppingCartOutlined,
  UserOutlined,
  // UserSwitchOutlined,
  // CopyOutlined
} from "@ant-design/icons";
import { Menu } from "antd";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { setActiveSection } from "../Redux/actionTypes";

function Sidebar() {
  const location = useLocation();
  const dispatch = useDispatch();
  const [selectedKeys, setSelectedKeys] = useState("/");
  const menuItems = [
    {
      label: "Dashbaord",
      icon: <AppstoreOutlined />,
      key: "/",
    },
    {
      label: "Tours",
      key: "/tours",
      icon: <ShopOutlined />,
      children: [
        {
          label: "Trips",
          key: "/trips",
        },
        {
          label: "Destination",
          key: "/destination",
        },
        {
          label: "Activity Types",
          key: "/activitytype",
        },
        {
          label: "Trip Type",
          key: "/triptype",
        },

      ],
    },
    {
      label: "Customers",
      key: "/customers",
      icon: <UserOutlined />,
    },
    // {
    //   label: "Vendors",
    //   key: "/vendors",
    //   icon: <UserSwitchOutlined />,
    // },
    {
      label: "Bookings",
      key: "/bookings",
      icon: <ShoppingCartOutlined />,
    },
    // {
    //   label: "Demo Bookings",
    //   key: "/bookings-of-demo",
    //   icon: <SettingOutlined />,
    // },
    // {
    //   label: "Quotations",
    //   key: "/quotations",
    //   icon: <CopyOutlined />,
    // },
    // {
    //   label: "Settings",
    //   key: "/settings",
    //   icon: <SettingOutlined />,
    // },
  ];

  useEffect(() => {
    const pathName = location.pathname;
    setSelectedKeys(pathName);
    dispatch(setActiveSection(pathName.slice(1)));
  }, [location.pathname, dispatch]);

  const navigate = useNavigate();
  return (
    <div className="SideMenu">
      <Menu
        className="SideMenuVertical"
        onClick={(item) => {
          navigate(item.key);
        }}
        selectedKeys={[selectedKeys]}
        defaultOpenKeys={["/catalog"]}
        mode="inline"
        items={menuItems}
      ></Menu>
    </div>
  );
}
export default Sidebar;
