import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";

const DonutChart = ({data}) => {
  const [series, setSeries] = useState([]);
  const [options, setOptions] = useState({
    chart: {
      width: 380,
      type: 'donut',
    },
    dataLabels: {
      enabled: true,
    },
    labels: data.map(item => (item.name)),
    title: {
      text: 'Best Selling Trips',
      align: 'left',
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            show: true,
          },
        },
      },
    ],
    legend: {
      position: 'bottom',
    },
  });

  useEffect(() => {
    if (data && data.length) {
      const updatedSeries = data.map(item => (item.quantitySold));
      const updatedLabels = data.map(item => (item.name));

      setSeries(updatedSeries);
      setOptions(prevGraphData => ({
        ...prevGraphData,
        labels: updatedLabels,
      }));
    }
  }, [data]);

  return (
    <div>
      <Chart
        options={options}
        series={series}
        type="donut"
        height={350}
      />
    </div>
  );
};

export default DonutChart;
