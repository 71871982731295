import React, { useState, useEffect } from 'react';
import { Table } from 'antd';
import { DeleteFilled } from '@ant-design/icons';

const CustomerTable = ({ allCustomers, searchValue }) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [filteredCustomers, setFilteredCustomers] = useState([]);
  const [allCustomersFormatted, setAllCustomerFormatted] = useState([]);
  const formatDate = (date) => {
    const formattedDate = new Date(date);
    return formattedDate.toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  }

  const sortByDate = (a, b) => {
    const datePartsA = a.joiningDate.split(" ");
    const datePartsB = b.joiningDate.split(" ");

    const yearA = parseInt(datePartsA[2]);
    const monthA = parseInt(datePartsA[0].toLowerCase() === "january" ? 0 : datePartsA[0].toLowerCase() === "february" ? 1 : NaN);
    const dayA = parseInt(datePartsA[1]);

    const yearB = parseInt(datePartsB[2]);
    const monthB = parseInt(datePartsB[0].toLowerCase() === "january" ? 0 : datePartsB[0].toLowerCase() === "february" ? 1 : NaN);
    const dayB = parseInt(datePartsB[1]);

    if (yearA !== yearB) {
      return yearA - yearB;
    } else if (monthA !== monthB) {
      return monthA - monthB;
    } else {
      return dayA - dayB;
    }
  };

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
    },
    {
      title: 'JOINING DATE',
      dataIndex: 'joiningDate',
      sorter: (a, b) => sortByDate(a, b),
    },
    {
      title: 'NAME',
      dataIndex: 'name',
      // sorter: (a, b) => a.name.length - b.name.length,
    },
    {
      title: 'EMAIL',
      dataIndex: 'email',
      sorter: (a, b) => a.email.localeCompare(b.email),
    },
    {
      title: 'PHONE',
      dataIndex: 'phone',
    },
    {
      title: 'ACTIONS',
      dataIndex: 'action',
      render: (text, record) => {
        return (
          <>
            <DeleteFilled style={{ fontSize: '20px', color: 'red' }} />
          </>
        );
      },
    },
  ];

  useEffect(() => {
    const filteredCustomers = allCustomersFormatted.filter((orderObj) => {
      if (searchValue) {
        const searchLowerCase = searchValue.toLowerCase();
        return (
          (orderObj.name && orderObj.name.toLowerCase().includes(searchLowerCase)) ||
          (orderObj.email && orderObj.email.toLowerCase().includes(searchLowerCase)) ||
          (orderObj.phone && orderObj.phone.toLowerCase().includes(searchLowerCase))
        );
      }
      return true;
    });

    console.log(filteredCustomers);
    setFilteredCustomers(filteredCustomers);
  }, [searchValue]);


  useEffect(() => {
    const formattedData = allCustomers.map((customer, index) => ({
      id: customer.id,
      joiningDate: formatDate(customer.createdAt),
      name: customer.full_name,
      email: customer.email,
      phone: customer.mobile,
      action: '',
    }));
    setAllCustomerFormatted(formattedData);
    setFilteredCustomers(formattedData);
  }, [allCustomers]);


  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const hasSelected = selectedRowKeys.length > 0;

  return (
    <div>
      <Table
        columns={columns}
        dataSource={filteredCustomers}
        scroll={{ x: 'max-content' }}
      />
    </div>
  );
};

export default CustomerTable;
