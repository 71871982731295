import React, { useEffect } from "react";

import "./Styles/styles.css";
import { Sidebar, Navbar } from "./Layouts";
import { Layout } from "antd";
import { Content, Header } from "antd/es/layout/layout";
import Sider from "antd/es/layout/Sider";
import { Logo } from "./Common";
import PageContent from "./page/PageContent";
// import { userLogin } from "./api/user";
import LoginPage from "./page/LoginPage";
import Cookies from "universal-cookie";
import { useLocation } from "react-router-dom";

function App() {
  const pathname = useLocation().pathname;
  const cookie = new Cookies();
  const token = cookie.get("token");
  // const loginUser = async () => {
  //   const otplessUserToken = {
  //     token: "298a86c837474be8a869796177429d2e",
  //     email: "mnshkmr746@gmail.com",
  //     waName: "Manish Kumar",
  //   };
  //   const userData = await userLogin(otplessUserToken);
  //   const { auth_token } = userData.data.data;
  //   localStorage.setItem("auth_token", auth_token);
  // };

  // useEffect(() => {
  //   loginUser();
  // }, []);

  useEffect(() => {
    if (pathname !== undefined) {
      renderComponent(pathname);
    }
  }, [pathname]);

  const renderComponent = (location) => {
    const path = location === undefined ? window.location.pathname : location;
    if (path === "/login" || token === undefined) {
      return <LoginPage />;
    } else if (path === "/signup" || token === undefined) {
      return <LoginPage />;
    } else {
      return (
        <Layout style={{ backgroundColor: "#FAFAFB" }}>
          <Sider
            breakpoint="lg"
            collapsedWidth="0"
            onBreakpoint={(broken) => {
              console.log(broken);
            }}
            onCollapse={(collapsed, type) => {
              console.log(collapsed, type);
            }}
            style={{
              padding: "20px 0",
              background: "white",
              height: "100vh",
            }}
          >
            <Logo />
            <Sidebar />
          </Sider>
          <Layout style={{ backgroundColor: "#FAFAFB" }}>
            <Header style={{ padding: 0, background: "#FAFAFB" }}>
              <Navbar />
            </Header>
            <Content style={{ margin: "1rem" }}>
              <PageContent></PageContent>
            </Content>
          </Layout>
        </Layout>
      );
    }
  };

  return renderComponent();
}

export default App;
