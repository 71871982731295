import tourApiService from "./tourApiService";

// GET request
export const getAllTripsType = async () => {
  return tourApiService.get('trip_type');
};

// GET tripsType by id
export const getTripsType = async (tripsTypeId) => {
  return tourApiService.get(`trip_type/${tripsTypeId}`, "GET");
};

// create tripsType
export const createTripsType = async (data) => {
  return tourApiService.post("trip_type", data);
};

// update tripsType
export const updateTripsType = async (tripsTypeId, data) => {
  return tourApiService.patch(`trip_type/${tripsTypeId}`, data);
};

// DELETE tripsType
export const deleteTripsType = async (tripsTypeId) => {
  return tourApiService.delete(`trip_type/${tripsTypeId}`, "DELETE");
};