import React, { useState } from "react";
import "../Styles/login.css";
import { Col, Form, Input, Button, Spin, message } from "antd";
import { LogoPng } from "../Icons";
import { ReactSVG } from "react-svg";
import { adminUserLogin } from "../api/user";
import loginImage from "../assets/images/loginImage.png";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";

const LoginPage = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const cookie = new Cookies();
  const handleSubmit = async (body) => {
    setIsLoading(true);
    setError(false);
    try {
      const { data } = await adminUserLogin(body);
      if (data.error === false) {
        setIsLoading(false);
        cookie.set("token", data.token);
        message.success("Logged in successfully");
        navigate("/");
      } else {
        setError(data.message);
        throw new Error("Login failed!");
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      message.error("Login Failed!");
    }
  };

  return (
    <div className="main-wrapper">
      <Form className="login-form" onFinish={handleSubmit}>
        <div className="logo">
          {/* <ReactSVG src={LogoPng} /> */}
          <img
            alt="logo"
            src={LogoPng}
            width={300}
            height={100}
          />
          <p
            style={{
              fontSize: "24px",
              fontWeight: "bold",
              paddingTop: "10px",
            }}
          >
            Hi, Please Login.
          </p>
        </div>
        <div className="input-box">
          <Col span={24}>
            <label htmlFor="">Email</label>
            <Form.Item
              name="email"
              rules={[
                {
                  required: true,
                  message: "Please enter your Email",
                },
              ]}
            >
              <Input
                className="form-input"
                type="email"
                placeholder="Please enter your Email"
              />
            </Form.Item>
          </Col>
        </div>
        <div className="input-box">
          <Col span={24}>
            <label htmlFor="">Password</label>
            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: "Please enter your Password",
                },
              ]}
            >
              <Input
                className="form-input"
                type="password"
                placeholder="Please enter your Password"
              />
            </Form.Item>
          </Col>
        </div>
        {typeof error === "string" && (
          <div className="error-section">
            <p>{error}</p>
          </div>
        )}
        <div className="btn-wrapper">
          <Button className="login-btn" type="primary" htmlType="submit">
            {isLoading ? <Spin /> : "LOGIN"}
          </Button>
        </div>
      </Form>
      <div className="login-side-image">
        <img className="login-image" src={loginImage} alt="" />
      </div>
    </div>
  );
};

export default LoginPage;
