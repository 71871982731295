import React from "react";
import Chart from "react-apexcharts";

const Graph = ({graphData, type, height}) => {
  return (
      <Chart
        options={graphData.options}
        series={graphData.series}
        type={type}
        height={height || 350}
      />
  );
};

export default Graph;
