import { CURRENT_PAGE } from "../actionTypes/constant";

const INITIAL_STATE = {
  lists: [
    "dashboard",
    "wishlist",
    "orders",
    "funds",
    "market conditions",
    "positions",
    "settings",
  ],
  active: "dashboard",
};

const Menu = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CURRENT_PAGE:
      return {
        ...state,
        active: action.payload,
      };

    default:
      return state;
  }
};

export default Menu;
