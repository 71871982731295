import {
  PlusOutlined,
  SearchOutlined
} from "@ant-design/icons";
import { Button, Col, Input, Row } from "antd";
import { useEffect, useState } from "react";
import TripTypeTable from "../Components/Table/TripTypeTable";
import AddTripType from "../Components/AddTripType";
import { getAllTripsType } from "../api/tripsType";

const buttonStyle = {
  minWidth: "110px",
  height: "40px",
  padding: "20px, 0px, 20px, 0px",
  borderRadius: "6px",
  marginRight: "10px",
};

const TripTypePage = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [tripType, setTripType] = useState([]);
  const [selectedTripType, setSelectedTripType] = useState(null);
  const [searchText, setSearchText] = useState("");

  // const handleMenuClick = (e) => {
  //   console.log("click", e);
  // };
  const onTripTypeSelected = (tripRecords, mode) => {
    if(mode === 'edit') {
      setSelectedTripType(tripRecords);
      setIsDrawerOpen(true);
    }
  };
  const onTripTypeUpdated = () => {
    getTripTypeFromApi();
  }

  const getTripTypeFromApi = async() => {
    try{
      const tripTypeData =  await getAllTripsType();
      setTripType(tripTypeData?.data?.data)
    } catch(error){
      console.error('Error fetching TripType:', error);
    }
    
  }
  useEffect(()=>{
    getTripTypeFromApi()
  }, [])
  return (
    <>
      <div>
        <Row gutter={16} style={{ marginTop: "20px" }}>
          <Col span={18}>
            {/* Content for the first column in the first row */}
            <Row gutter={16}>
              <Col>
                <Input
                  size="large"
                  placeholder="Search Trip Type"
                  suffix={<SearchOutlined />}
                  onChange={(input) => setSearchText(input.target.value)}
                />
              </Col>
            </Row>
          </Col>
          <Col span={6} style={{ textAlign: "right" }}>
            {/* Content for the second column in the first row */}
            <Button
              icon={<PlusOutlined />}
              type="primary"
              style={{ ...buttonStyle, backgroundColor: "#324966" }}
              onClick={(e) => {
                console.log("add button clicked");
                setSelectedTripType(null);
                setIsDrawerOpen(true);
              }}
            >
              Add Trip Type
            </Button>
          </Col>
        </Row>
        <Row gutter={16} style={{ marginTop: "20px" }}>
          <Col span={24}>
            <TripTypeTable searchText={searchText} tripType={tripType} onTripTypeSelected={onTripTypeSelected} onTripTypeUpdated={onTripTypeUpdated}/>
          </Col>
        </Row>
        <Row gutter={16} style={{ marginTop: "20px" }}>
          <Col span={24}>
           <AddTripType isDrawerOpen={isDrawerOpen} setIsDrawerOpen={setIsDrawerOpen} selectedTripType={selectedTripType} onTripTypeUpdated={onTripTypeUpdated} />
          </Col>
        </Row>
      </div>
    </>
  );
};

export default TripTypePage;
