import { SearchOutlined, UserOutlined } from "@ant-design/icons";
import { Col, Input, Row } from "antd";
import { useState } from "react";
import { useEffect } from "react";
import React from "react";
import { getAllVendor } from "../api/vendor";
import VendorTable from "../Components/Table/VendorTable";
import EditVendor from "../Components/Modal/EditVendor";

const VendorsPage = () => {
  const [allVendors, setAllVendors] = useState([]);
  const [selectedVendor, setSelectedVendor] = useState(undefined);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [isViewMode, setIsViewMode] = useState(false);

  const onVendorSelected = (vendor) => {
    setSelectedVendor(vendor);
    setIsModalOpen(true);
  };

  const handleMenuClick = (e) => {
    console.log("click", e);
  };
  const items = [
    {
      label: "1st menu item",
      key: "1",
      icon: <UserOutlined />,
    },
    {
      label: "2nd menu item",
      key: "2",
      icon: <UserOutlined />,
    },
    {
      label: "3rd menu item",
      key: "3",
      icon: <UserOutlined />,
      danger: true,
    },
    {
      label: "4rd menu item",
      key: "4",
      icon: <UserOutlined />,
      danger: true,
      disabled: true,
    },
  ];
  const handleSearch = (e) => {
    setSearchValue(e.target.value);
  };
  const fetchData = async () => {
    try {
      const response = await getAllVendor();
      if (response) {
        setAllVendors(response);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);
  return (
    <>
      <div>
        <Row gutter={16} style={{ marginTop: "20px" }}>
          <Col span={18}>
            {/* Content for the first column in the first row */}
            <Row gutter={16}>
              <Col span={12}>
                <Input
                  placeholder="Search by name/email/phone"
                  suffix={<SearchOutlined />}
                  onChange={handleSearch}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row gutter={16} style={{ marginTop: "20px" }}>
          <Col span={24}>
            <VendorTable
              onVendorSelected={onVendorSelected}
              fetchData={fetchData}
              allVendors={allVendors}
              searchValue={searchValue}
              setIsViewMode={setIsViewMode}
            />
          </Col>
        </Row>
        <EditVendor
          fetchData={fetchData}
          isViewMode={isViewMode}
          selectedVendor={selectedVendor}
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
        />
      </div>
    </>
  );
};

export default VendorsPage;
