import React, { useState } from 'react';
import { Card, Image} from 'antd';
import icon1 from "../Icons/icon1.svg"
import icon2 from "../Icons/icon2.svg"
import icon3 from "../Icons/icon3.svg"
import icon4 from "../Icons/icon4.svg"

const CardSmall = ({ cardData }) => {
  const [circleStyle, setCircleStyle] = useState({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'rgba(91, 147, 255, 0.1)', // Set your desired background color
    borderRadius: '50%',
    width: '60px', // Adjust the size of the circle as needed
    height: '60px',
  })
  return (
    <Card
      bordered={false}
      style={{
        height: "116px",
      }}
    >
      <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <div style={{ flex: 3, textAlign: 'center' }}>
        <div style={circleStyle}>
          <Image src={cardData.icon === 'icon1' ? icon1 : cardData.icon === 'icon2'? icon2 : cardData.icon === 'icon3'? icon3 : icon4} width={20} preview={false} />
        </div>
      </div>
      <div style={{ flex: 7, textAlign: 'left' }}>
        <Card.Meta
          title={<span style={{ fontWeight: 700, fontSize: '22px', marginBottom: '0' }}>{cardData.title}</span>}
          description={<span style={{Weight: "400", Size: "14px", color: "#080305"}}>{cardData.description}</span>}
        />
      </div>
    </div>
    </Card>
  );
}
export default CardSmall