import {
  Button,
  Card,
  Col,
  Drawer,
  Form,
  Input,
  Row,
  Space,
  Spin,
  message,
} from "antd";
import { useForm } from "antd/es/form/Form";
import TextArea from "antd/lib/input/TextArea";
import React, { useState } from "react";
import { updateVendor, verifyVendor } from "../../api/vendor";

const EditVendor = ({
  fetchData,
  isViewMode,
  isModalOpen,
  setIsModalOpen,
  selectedVendor,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [form] = useForm();
  if (selectedVendor) {
    form.setFieldsValue(selectedVendor);
  } else {
    form.resetFields();
  }
  const handleSubmit = async (body) => {
    let values = {
      ...body,
      id: selectedVendor.id,
    };
    try {
      const data = await updateVendor(values);
      if (data) {
        setIsModalOpen(false);
        fetchData();
        message.success("Updated successfully");
      }
    } catch (error) {
      message.error("Failed to update vendor information!");
    }
  };
  const handleVerify = async () => {
    setIsLoading(true);
    try {
      const data = await verifyVendor(selectedVendor.id);
      if (data.error === false) {
        console.log(data);
        fetchData();
        setIsLoading(false);
        setIsModalOpen(false);
      }
    } catch (error) {
      setIsLoading(false);
      message.error("Failed to verify vendor");
    }
  };

  return (
    <Drawer
      title={
        <div>
          <Card.Meta
            title={
              <span style={{ marginBottom: "0" }}>
                {"Edit Vendor Information"}
              </span>
            }
            description={
              <span style={{ fontWeight: "400", color: "#080305" }}>
                {"Edit vendor information from here."}
              </span>
            }
          />
        </div>
      }
      width={720}
      onClose={() => setIsModalOpen(false)}
      open={isModalOpen}
      styles={{
        body: {
          paddingBottom: 80,
        },
      }}
    >
      <Form form={form} layout="vertical" onFinish={handleSubmit}>
        <Row gutter={20}>
          <Col span={12}>
            <Form.Item
              name="full_name"
              label="Full Name"
              rules={[
                {
                  required: true,
                  message: "Please enter your full name",
                },
              ]}
            >
              <Input
                disabled={isViewMode}
                type="text"
                placeholder="Full name"
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="brandName"
              label="Brand Name"
              rules={[
                {
                  required: true,
                  message: "Please enter product brand",
                },
              ]}
            >
              <Input
                disabled={isViewMode}
                type="text"
                placeholder="Brand name"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={20}>
          <Col span={12}>
            <Form.Item
              name="email"
              label="Email"
              rules={[
                {
                  required: true,
                  message: "Please enter your email!",
                },
              ]}
            >
              <Input disabled={isViewMode} type="text" placeholder="Email" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="mobile"
              label="Phone number"
              rules={[
                {
                  required: true,
                  message: "Please enter your phone number",
                },
              ]}
            >
              <Input disabled={isViewMode} type="text" placeholder="mobile" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={20}>
          <Col span={12}>
            <Form.Item
              name="cin"
              label="CIN"
              rules={[
                {
                  required: true,
                  message: "Please enter your cin",
                },
              ]}
            >
              <Input
                disabled={isViewMode}
                type="text"
                placeholder="CIN number"
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="gstin"
              label="GSTIN"
              rules={[
                {
                  required: true,
                  message: "Please enter your GSTIN!",
                },
              ]}
            >
              <Input disabled={isViewMode} type="text" placeholder="GSTIN" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={20}>
          <Col span={12}>
            <Form.Item
              name="pan"
              label="PAN Number"
              rules={[
                {
                  required: true,
                  message: "Please enter your pan number",
                },
              ]}
            >
              <Input
                disabled={isViewMode}
                type="text"
                placeholder="Full name"
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="registeredAddress"
              label="Registered Address"
              rules={[
                {
                  required: true,
                  message: "Please enter your address",
                },
              ]}
            >
              <TextArea
                disabled={isViewMode}
                rows={4}
                placeholder="Registered Address"
              />
            </Form.Item>
          </Col>
        </Row>
        {isViewMode ? (
          <Row gutter={20} style={{ marginTop: "2rem", width: "100%" }}>
            <Col span={24}>
              <Form.Item
                wrapperCol={{
                  offset: 8,
                  span: 16,
                }}
              >
                <Space>
                  <Button onClick={() => setIsModalOpen(false)}>Cancel</Button>
                  <Button danger onClick={() => setIsModalOpen(false)}>
                    Discard
                  </Button>
                  <Button
                    disabled={selectedVendor?.isVerified}
                    type="primary"
                    onClick={() => handleVerify()}
                    style={{ backgroundColor: "#04B2A9" }}
                  >
                    {isLoading ? (
                      <Spin />
                    ) : selectedVendor?.isVerified ? (
                      "Verified"
                    ) : (
                      "Verify"
                    )}
                  </Button>
                </Space>
              </Form.Item>
            </Col>
          </Row>
        ) : (
          <Row gutter={20} style={{ marginTop: "2rem" }}>
            <Col span={24}>
              <Form.Item
                wrapperCol={{
                  offset: 8,
                  span: 16,
                }}
              >
                <Space>
                  <Button
                    style={{ width: "187px" }}
                    onClick={() => setIsModalOpen(false)}
                    danger
                  >
                    Cancel
                  </Button>
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{ width: "260px", backgroundColor: "#04B2A9" }}
                  >
                    Update
                  </Button>
                </Space>
              </Form.Item>
            </Col>
          </Row>
        )}
      </Form>
    </Drawer>
  );
};

export default EditVendor;
