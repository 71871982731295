import { PlusOutlined, SearchOutlined } from "@ant-design/icons";
import { Button, Col, Input, Row } from "antd";
import { useEffect, useState } from "react";
import { getAllTrips } from "../api/trips";
import React from "react";
import { getAllDestination } from "../api/destination";
import TripsTable from "../Components/Table/TripsTable";
import AddTrips from "../Components/AddTrips";
import { getAllActivityType } from "../api/activityType";
import { getAllTripsType } from "../api/tripsType";

const buttonStyle = {
  minWidth: "110px",
  height: "40px",
  padding: "20px, 0px, 20px, 0px",
  borderRadius: "6px",
  marginRight: "10px",
};

const TripsPage = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedTrips, setSelectedTrips] = useState(null);
  const [trips, setTrips] = useState([]);
  const [destinationList, setDestinationList] = useState(undefined);
  const [activityList, setActivityList] = useState(undefined);
  const [tripTypeList, setTripTypeList] = useState(undefined);
  const [searchText, setSearchText] = useState("");
  // const handleMenuClick = (e) => {
  //   console.log("click", e);
  // };

  const onTripsSelected = (selectedTrips) => {
    setSelectedTrips(selectedTrips);
    setIsDrawerOpen(true);
  };
  const onTripsUpdated = () => {
    getTripsFromApi();
  };
  const getTripsFromApi = async () => {
    try {
      const allTripsData = await getAllTrips();
      let sortedAllTripsData = allTripsData?.data?.data.sort((a, b) => {
        const dateA = new Date(a.createdAt).getTime();
        const dateB = new Date(b.createdAt).getTime();
        return dateB - dateA;
      });
      setTrips(sortedAllTripsData);
    } catch (error) {
      console.error("Error fetching trips:", error);
    }
  };
  const getDestinationFromApi = async () => {
    try {
      const destinationData = await getAllDestination();
      setDestinationList(destinationData?.data?.data);
    } catch (error) {
      console.error("Error fetching destinations:", error);
    }
  };
  const getActivityTypeFromApi = async () => {
    try {
      const activityData = await getAllActivityType();
      setActivityList(activityData?.data?.data);
    } catch (error) {
      console.error("Error fetching activity:", error);
    }
  };
  const getTripTypeFromApi = async () => {
    try {
      const tripsTypeData = await getAllTripsType();
      setTripTypeList(tripsTypeData?.data?.data);
    } catch (error) {
      console.error("Error fetching trip type:", error);
    }
  };
  useEffect(() => {
    getTripsFromApi();
    getDestinationFromApi();
    getActivityTypeFromApi();
    getTripTypeFromApi();
  }, []);
  return (
    <>
      <div>
        <Row gutter={16} style={{ marginTop: "20px" }}>
          <Col span={18}>
            {/* Content for the first column in the first row */}
            <Row gutter={16}>
              <Col span={12}>
                <Input
                  size="large"
                  placeholder="Search Trip"
                  suffix={<SearchOutlined />}
                  onChange={(input) => setSearchText(input.target.value)}
                />
              </Col>
            </Row>
          </Col>
          <Col span={6} style={{ textAlign: "right" }}>
            {/* Content for the second column in the first row */}
            <Button
              icon={<PlusOutlined />}
              type="primary"
              style={{ ...buttonStyle, backgroundColor: "#324966" }}
              onClick={(e) => {
                setSelectedTrips(null);
                setIsDrawerOpen(true);
              }}
            >
              Add Trip
            </Button>
          </Col>
        </Row>
        <Row gutter={16} style={{ marginTop: "20px" }}>
          <Col span={24}>
            <TripsTable
              trips={trips}
              onTripsSelected={onTripsSelected}
              onTripsUpdated={onTripsUpdated}
              searchText={searchText}
            />
          </Col>
        </Row>
        <AddTrips
          isDrawerOpen={isDrawerOpen}
          setIsDrawerOpen={setIsDrawerOpen}
          destinationList={destinationList}
          activityList={activityList}
          tripTypeList={tripTypeList}
          selectedTrips={selectedTrips}
          onTripsUpdated={onTripsUpdated}
        />
      </div>
    </>
  );
};

export default TripsPage;
