import tourApiService from "./tourApiService";

// GET request
export const getAllActivityType = async () => {
  return tourApiService.get('activity_type');
};

// GET activityType by id
export const getActivityType = async (ActivityTypeId) => {
  return tourApiService.get(`activity_type/${ActivityTypeId}`, "GET");
};

// create activityType
export const createActivityType = async (ActivityTypeData) => {
  return tourApiService.post("activity_type", ActivityTypeData);
};

// update activityType
export const updateActivityType = async (ActivityTypeId, ActivityTypeData) => {
  return tourApiService.patch(`activity_type/${ActivityTypeId}`, ActivityTypeData);
};

// DELETE activityType
export const deleteActivityType = async (ActivityTypeId) => {
  return tourApiService.delete(`activity_type/${ActivityTypeId}`, "DELETE");
};