import React from "react";
import { Profile } from "../Common";
import { useSelector } from "react-redux";
import { Typography } from "antd";
const { Title } = Typography;

const Navbar = () => {
  const reduxData = useSelector((state) => state);

  const menu = reduxData.menu;
  console.log("menu ==>", menu);

  return (
    menu.lists && (
      <div className="navbarMainMenu">
        <Title level={2} style={{ padding: "10px 0 0 10px" }}>
          Dashboard
        </Title>
        <Profile />
      </div>
    )
  );
};

export default Navbar;
