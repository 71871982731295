import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Drawer,
  Form,
  Input,
  Row,
  Space,
  // Switch,
  Select
} from "antd";
import { useForm } from "antd/es/form/Form";
import { createDestination, updateDestination } from "../api/destination";
import { countries } from "./DestinationRegion/DestinationData";

const AddDestination = ({ isDrawerOpen, setIsDrawerOpen, selectedDestination, onDestinationUpdated }) => {
  const [open, setOpen] = useState(isDrawerOpen);
  const [form] = useForm();
  const [stateOptions, setStateOptions] = useState([]);

  const handleCountryChange = (value) => {
    const selectedCountry = countries.find(country => country.value === value);
    if (selectedCountry) {
      setStateOptions(selectedCountry.states);
    } else {
      setStateOptions([]);
    }
  };

  const onFinish = async (formData) => {
    if (selectedDestination) {
      formData.id = selectedDestination.id;
      await updateDestination(formData);
    } else {
      await createDestination(formData)
    }
    setIsDrawerOpen(false);
    onDestinationUpdated();
    console.log("Success:", formData);
  }


  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const onClose = () => {
    setOpen(false);
    setIsDrawerOpen(false);
    form.resetFields();
  };

  useEffect(() => {
    setOpen(isDrawerOpen);
  }, [isDrawerOpen]);

  useEffect(() => {
    if (selectedDestination) {
      form.setFieldsValue(selectedDestination);
    } else {
      form.resetFields();
    }
  }, [selectedDestination, form]);

  return (
    <>
      <Drawer
        title={
          <div>
            <Card.Meta
              title={
                <span style={{ marginBottom: "0" }}>{`${selectedDestination ? "Update" : "Add"} Destination`}</span>
              }
              description={
                <span style={{ fontWeight: "400", color: "#080305" }}>
                  {`${selectedDestination ? "Update" : "Add"} your destination and necessary information from here`}
                </span>
              }
            />
          </div>
        }
        width={500}
        onClose={onClose}
        open={open}
        styles={{
          body: {
            paddingBottom: 80,
          },
        }}
      >
        <Form
          form={form}
          layout="vertical"
          requiredMark={false}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Row gutter={20}>
            <Col span={24}>
              <Form.Item
                name="name"
                label="Destination Name"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Destination Name",
                  },
                ]}
              >
                <Input placeholder="Name of Destination" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={20}>
            <Col xs={24} sm={12}>
              <Form.Item
                name="country"
                label="Select Destination Country"
                rules={[
                  {
                    required: true,
                    message: "Please Select Country",
                  },
                ]}
              >
                <Select
                  showSearch
                  style={{ width : "100%" }}
                  placeholder="Select Country"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0)
                  }
                  filterSort={(optionA, optionB) =>
                    (optionA?.children ?? "")
                      .toLowerCase()
                      .localeCompare((optionB?.children ?? "").toLowerCase())
                  }
                  onChange={handleCountryChange}
                >
                  {countries.map(country => (
                    <Select.Option key={country.value} value={country.value}>{country.label}</Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item
                name="state"
                label="Select Destination State"
                rules={[
                  {
                    required: true,
                    message: "Please Select State",
                  },
                ]}
              >
                <Select
                  showSearch
                  style={{ width : "100%" }}
                  placeholder="Select State"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0)
                  }
                  filterSort={(optionA, optionB) =>
                    (optionA?.children ?? "")
                      .toLowerCase()
                      .localeCompare((optionB?.children ?? "").toLowerCase())
                  }
                >
                  {stateOptions.map(state => (
                    <Select.Option key={state.value} value={state.value}>{state.label}</Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24} style={{ textAlign: "center" }}>
              <Form.Item
                wrapperCol={{
                  offset: 12,
                  span: 16,
                }}
                style={{ marginTop: 20 }}
              >
                <Space>
                  <Button danger onClick={onClose}>
                    Cancel
                  </Button>
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{ backgroundColor: "#324966" }}
                  >
                    {selectedDestination ? "Update" : "Save"}
                  </Button>
                </Space>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Drawer>
    </>
  );
};

export default AddDestination;
