import React, { useEffect, useState } from "react";
import Graph from "./Graph";

const GraphLine = ({data}) => {
  const [xAxis, setXAxis] = useState([]);
  const [yAxis, setYAxis] = useState([]);
  const graphData = {
    options: {
      chart: {
        height: 350,
        type: "line",
        zoom: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
      },
      title: {
        text: "Weekly Sales",
        align: "left",
      },
      grid: {
        row: {
          colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
          opacity: 0.5,
        },
      },
      xaxis: {
        categories: xAxis, // Using state values for xAxis
      },
    },
    series: [
      {
        name: "Sales",
        data: yAxis,
      },
    ],
  };
  function getXAndYAxis(salesByDay) {
    const xAxis = Object.keys(salesByDay); // Extracting keys as x-axis values
    const yAxis = Object.values(salesByDay); // Extracting values as y-axis values
  
    return { xAxis, yAxis };
  }
  useEffect(() => {
    const { xAxis: newXAxis, yAxis: newYAxis } = getXAndYAxis(data);    
    // Set state with the values
    setXAxis(newXAxis);
    setYAxis(newYAxis);
  }, [data])

  return (
    <div>
      <Graph
        graphData={graphData}
        type="line"
        height={350}
      />
    </div>
  );
};

export default GraphLine;
