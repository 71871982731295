import React, { useEffect, useState } from 'react'
import { SearchOutlined } from "@ant-design/icons";
import { Col, Input, Row } from 'antd'
import { getListDemoBooking } from '../api/bookDemo';
import ViewDemoBooking from '../Components/Modal/ViewDemoBooking';
import QuotationTable from '../Components/Table/QuotationTable';
import { getAllqutations } from '../api/booking';
import ViewQuotation from '../Components/Modal/ViewQuotation';

const Quotations = () => {
	const [searchValue, setSearchValue] = useState("");
	const [allQuotations, setAllQuotations] = useState([]);
	const [selectedQuotation, setSelectedQuotation] = useState(null);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [isViewMode, setIsViewMode] = useState(false);

	const onQuotationSelected = (bookingObj) => {
		setSelectedQuotation(bookingObj);
		setIsModalOpen(true);
	};

	const handleSearch = (e) => {
		setSearchValue(e.target.value);
	};

	const fetchData = async () => {
		try {
			const response = await getAllqutations();
			if (response) {
				setAllQuotations(response);
			}
		} catch (error) {
			console.error("Error fetching data:", error);
		}
	};

	useEffect(() => {
		fetchData();
	}, []);

	return (
		<>
			<div>
				<Row gutter={16} style={{ marginTop: "20px" }}>
					<Col span={18}>
						{/* Content for the first column in the first row */}
						<Row gutter={16}>
							<Col span={12}>
								<Input
									placeholder="Search by name/email/phone"
									suffix={<SearchOutlined />}
									onChange={handleSearch}
								/>
							</Col>
						</Row>
					</Col>
				</Row>
				<Row gutter={16} style={{ marginTop: "20px" }}>
					<Col span={24}>
						<QuotationTable
							onQuotationSelected={onQuotationSelected}
							fetchData={fetchData}
							allQuotations={allQuotations}
							searchValue={searchValue}
							setIsViewMode={setIsViewMode}
						/>
					</Col>
				</Row>
				<ViewQuotation
					fetchData={fetchData}
					selectedQuotation={selectedQuotation}
					isModalOpen={isModalOpen}
					setIsModalOpen={setIsModalOpen}
					isViewMode={isViewMode}
				/>
			</div>
		</>
	)
}

export default Quotations