import tourApiService from "./tourApiService";

// GET request
export const getAllTrips = async () => {
  return tourApiService.get('trip');
};

// GET trips by id
export const getTrips = async (tripsId) => {
  return tourApiService.get(`trip/${tripsId}`, "GET");
};

// create trips
export const createTrips = async (data) => {
  return tourApiService.post("trip", data);
};

// update trips
export const updateTrips = async (data) => {
  return tourApiService.patch("trip", data);
};

// update trips
export const updateTripsPublish = async (data) => {
  return tourApiService.patch("trip/publish", data);
};

// DELETE trips
export const deleteTrips = async (tripsId) => {
  return tourApiService.delete(`trip/${tripsId}`, "DELETE");
};