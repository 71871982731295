import authApiService from "./authApiService";

// export const userLogin = async (tokenObj) => {
//   return authApiService.post(`/auth/otpless-user-login`, tokenObj);
// };

export const adminUserLogin = async (body) => {
  return authApiService.put(`/auth/admin-user/login`, body);
};

export const adminUserSignUp = async (body) => {
  return authApiService.post(`/auth/admin-user/signup`, body);
};

export const sendMail = async (body) => {
  return authApiService.post(`/auth/email/send`, body);
};
