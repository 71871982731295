import { Button, Col, Form, Input, Row, Select, Space } from "antd";
import React, { useEffect, useState } from "react";

function SettingPage() {
const [timezones, setTimezones] = useState([]);
  const onFinish = (formData) => {
    console.log(formData);
  };
  const onFinishFailed = (error) => {
    console.log(error);
  };
  const currencyOptions = [
    {
      value: "rupees",
      label: "💲 Rupees",
    },
    {
      value: "dollar",
      label: "💵 Dollar",
    },
    {
      value: "euro",
      label: "💶 Euro",
    },
    {
      value: "pound",
      label: "💷 Pound Sterling",
    },
    {
      value: "yen",
      label: "💴 Yen",
    },
    // Add more currencies with emojis as needed
  ];
  const options = [
    {
      value: "jack",
      label: "Jack",
    },
    {
      value: "lucy",
      label: "Lucy",
    },
    {
      value: "Yiminghe",
      label: "yiminghe",
    },
    {
      value: "disabled",
      label: "Disabled",
      disabled: true,
    },
  ];
  const handleChange = (selectedItem) => {
    console.log(selectedItem);
  };
  useEffect(() => {
    const getAllTimezones = () => {
      const timezones = Intl.supportedValuesOf('timeZone');
      return Array.from(timezones).map(timezone => ({
        value: timezone,
        label: timezone,
      }));
    };
    
    const allTimezones = getAllTimezones();
    setTimezones(allTimezones);
  }, []);
  return (
    <Row gutter={16} type="flex" justify="center" align="middle">
      <Col span={18}>
        <Form
          layout="vertical"
          hideRequiredMark
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="numberOfImages"
                label="Number of images per product"
                rules={[
                  {
                    required: true,
                    message: "Please enter the number",
                  },
                ]}
              >
                <Input type="number" placeholder="Please enter the number" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="vatNumber"
                label="Vat Number"
                rules={[
                  {
                    required: true,
                    message: "Please enter the Vat Number",
                  },
                ]}
              >
                <Input
                  type="number"
                  placeholder="Please enter the Vat Number"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={20}>
            <Col span={12}>
              <Form.Item
                name="defaultCurrency"
                label="Default Currency"
                rules={[
                  {
                    required: true,
                    message: "Please enter the Default Currency",
                  },
                ]}
              >
                <Select
                  style={{
                    width: "100%",
                  }}
                  placeholder="Default Currency"
                  onChange={handleChange}
                  options={currencyOptions}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="address"
                label="Address"
                rules={[
                  {
                    required: true,
                    message: "Please enter the address",
                  },
                ]}
              >
                <Input.TextArea
                  rows={1}
                  placeholder="please enter the instruction"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="defaultTimeZone"
                label="Default time zone"
                rules={[
                  {
                    required: true,
                    message: "Select the Default Time Zone",
                  },
                ]}
              >
                <Select
                  style={{
                    width: "100%",
                  }}
                  showSearch
                  placeholder="Default Time Zone"
                  onChange={handleChange}
                  options={timezones}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="postCode"
                label="Post Code"
                rules={[
                  {
                    required: true,
                    message: "Please enter the post code",
                  },
                ]}
              >
                <Input
                  maxLength={6}
                  type="number"
                  placeholder="Please enter Country Of Origin"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={20}>
            <Col span={12}>
              <Form.Item
                name="dateFormate"
                label="Default Date Formate"
                rules={[
                  {
                    required: true,
                    message: "Please enter the default date",
                  },
                ]}
              >
                <Select
                  style={{
                    width: "100%",
                  }}
                  placeholder="Default Date"
                  options={[
                    {
                      label: "DD/MM/YYYY",
                      value: "dd/mm/yyyy",
                    },
                    {
                      label: "YYYY/MM/DD",
                      value: "yyyy/mm/dd",
                    },
                  ]}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="mobileNumber"
                label="Mobile Number"
                rules={[
                  {
                    required: true,
                    message: "Please enter the mobile number",
                  },
                ]}
              >
                <Input
                  type="number"
                  placeholder="Please enter the mobile number"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={20}>
            <Col span={12}>
              <Form.Item
                name="shopName"
                label="Shop Name"
                rules={[
                  {
                    required: true,
                    message: "Please enter the shop name",
                  },
                ]}
              >
                <Input placeholder="Please enter the shop name" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="email"
                label="Email"
                rules={[
                  {
                    required: true,
                    message: "Please enter the email",
                  },
                ]}
              >
                <Input placeholder="Please enter the email" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={20}>
            <Col span={12}>
              <Form.Item
                name="companyName"
                label="Company Name"
                rules={[
                  {
                    required: true,
                    message: "Please enter the company name",
                  },
                ]}
              >
                <Input placeholder="Please enter the company name" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="website"
                label="Website"
                rules={[
                  {
                    required: true,
                    message: "Please enter the website",
                  },
                ]}
              >
                <Input placeholder="Please enter the website" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={20} style={{ marginTop: "2rem" }}>
            <Col
              span={24}
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ width: "260px", backgroundColor: "#ED302C" }}
                >
                  Update
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Col>
    </Row>
  );
}

export default SettingPage;
