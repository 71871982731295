import {
	Button,
	Card,
	Col,
	Drawer,
	Input,
	Row,
} from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import React from "react";

const ViewQuotation = ({
	fetchData,
	isModalOpen,
	setIsModalOpen,
	selectedQuotation,
	isViewMode
}) => {
	return (
		<Drawer
			title={
				<div>
					<Card.Meta
						title={
							<span style={{ marginBottom: "0" }}>
								{"View quotation details"}
							</span>
						}
						description={
							<span style={{ fontWeight: "400", color: "#080305" }}>
								{"View quotation information from here."}
							</span>
						}
					/>
				</div>
			}
			width={720}
			onClose={() => setIsModalOpen(false)}
			open={isModalOpen}
			styles={{
				body: {
					paddingBottom: 80,
				},
			}}
		>
			<Row gutter={20}>
				<Col span={12}>
					<p>Customer Name</p><br />
					<Input
						disabled={isViewMode}
						type="text"
						placeholder="Full name"
						value={`${selectedQuotation?.userName}`}
					/>
				</Col>
				<Col span={12}>
					<p>Email address</p><br />
					<Input
						disabled={isViewMode}
						type="text"
						placeholder="Customer email"
						value={selectedQuotation?.userEmail}
					/>
				</Col>
			</Row>
			<Row gutter={20} style={{ marginTop: '20px' }}>
				<Col span={12}>
					<p>Customer phone</p><br />
					<Input
						disabled={isViewMode}
						type="text"
						placeholder="Phone"
						value={selectedQuotation?.phoneNumber}
					/>
				</Col>
				<Col span={12}>
					<p>Status</p><br />
					<Input
						disabled={isViewMode}
						type="text"
						placeholder="Pending"
						value={selectedQuotation?.isApproved ? "Approve" : (selectedQuotation?.isRejected ? "Reject" : null)}
					/>
				</Col>
			</Row>
			<Button
				style={{ width: "150px", marginTop: '20px' }}
				onClick={() => setIsModalOpen(false)}
				danger
			>
				Close
			</Button>
		</Drawer>
	);
}

export default ViewQuotation