import React, { useEffect, useState } from 'react'
import { SearchOutlined } from "@ant-design/icons";
import { Col, Input, Row } from 'antd'
import DemoBookingsTable from '../Components/Table/DemoBookingsTable';
import { getListDemoBooking } from '../api/bookDemo';
import ViewDemoBooking from '../Components/Modal/ViewDemoBooking';

const DemoBookings = () => {
	const [searchValue, setSearchValue] = useState("");
	const [allDemoBookings, setAllDemoBookings] = useState([]);
  const [selectedBooking, setSelectedBoooking] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isViewMode, setIsViewMode] = useState(false);

	const onDemoBookingSelected = (bookingObj) => {
    setSelectedBoooking(bookingObj);
    setIsModalOpen(true);
  };

	const handleSearch = (e) => {
		setSearchValue(e.target.value);
	};

	const fetchData = async () => {
    try {
      const response = await getListDemoBooking();
      if (response) {
        setAllDemoBookings(response);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

	return (
		<>
			<div>
				<Row gutter={16} style={{ marginTop: "20px" }}>
					<Col span={18}>
						{/* Content for the first column in the first row */}
						<Row gutter={16}>
							<Col span={12}>
								<Input
									placeholder="Search by name/email/phone"
									suffix={<SearchOutlined />}
									onChange={handleSearch}
								/>
							</Col>
						</Row>
					</Col>
				</Row>
				<Row gutter={16} style={{ marginTop: "20px" }}>
					<Col span={24}>
						<DemoBookingsTable
							onDemoBookingSelected={onDemoBookingSelected}
							fetchData={fetchData}
							allDemoBookings={allDemoBookings}
							searchValue={searchValue}
							setIsViewMode={setIsViewMode}
						/>
					</Col>
				</Row>
				<ViewDemoBooking
					fetchData={fetchData}
          selectedBooking={selectedBooking}
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
					isViewMode={isViewMode}
        />
			</div>
		</>
	)
}

export default DemoBookings