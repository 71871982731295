import {
  PlusOutlined,
  SearchOutlined
} from "@ant-design/icons";
import { Button, Col, Input, Row } from "antd";
import { useEffect, useState } from "react";
import ActivityTypeTable from "../Components/Table/ActivityTypeTable";
import AddActivityType from "../Components/AddActivityType";
import { getAllActivityType } from "../api/activityType";

const buttonStyle = {
  minWidth: "110px",
  height: "40px",
  padding: "20px, 0px, 20px, 0px",
  borderRadius: "6px",
  marginRight: "10px",
};

const ActivityTypePage = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [activityType, setActivityType] = useState([]);
  const [selectedActivityType, setselectedActivityType] = useState(null);
  const [searchText, setSearchText] = useState("");

  // const handleMenuClick = (e) => {
  //   console.log("click", e);
  // };

  const onActivityTypeSelected = (activityRecord, mode) => {
    if (mode === 'edit') {
      setselectedActivityType(activityRecord);
      setIsDrawerOpen(true);
    }
  };
  const onActivityTypeUpdated = () => {
    getActivityTypeFromApi();
  }

  const getActivityTypeFromApi = async () => {
    try {
      const activityTypeData = await getAllActivityType();
      setActivityType(activityTypeData?.data?.data)
    } catch (error) {
      console.error('Error fetching activityType:', error);
    }
  }
  useEffect(() => {
    getActivityTypeFromApi()
  }, [])

  return (
    <>
      <div>
        <Row gutter={16} style={{ marginTop: "20px" }}>
          <Col span={18}>
            {/* Content for the first column in the first row */}
            <Row gutter={16}>
              <Col>
                <Input
                  size="large"
                  placeholder="Search Activity"
                  suffix={<SearchOutlined />}
                  onChange={(input) => setSearchText(input.target.value)}
                />
              </Col>
            </Row>
          </Col>
          <Col span={6} style={{ textAlign: "right" }}>
            {/* Content for the second column in the first row */}
            <Button
              icon={<PlusOutlined />}
              type="primary"
              style={{ ...buttonStyle, backgroundColor: "#324966" }}
              onClick={(e) => {
                console.log("add button clicked");
                setselectedActivityType(null);
                setIsDrawerOpen(true);
              }}
            >
              Add Activity
            </Button>
          </Col>
        </Row>
        <Row gutter={16} style={{ marginTop: "20px" }}>
          <Col span={24}>
            <ActivityTypeTable searchText={searchText} activityType={activityType} onActivityTypeSelected={onActivityTypeSelected} onActivityTypeUpdated={onActivityTypeUpdated} />
          </Col>
        </Row>
        <Row gutter={16} style={{ marginTop: "20px" }}>
          <Col span={24}>
            <AddActivityType isDrawerOpen={isDrawerOpen} setIsDrawerOpen={setIsDrawerOpen} selectedActivityType={selectedActivityType} onActivityTypeUpdated={onActivityTypeUpdated} />
          </Col>
        </Row>
      </div>
    </>
  );
};

export default ActivityTypePage;
