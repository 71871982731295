import React, { useState, useEffect } from "react";
import { Card, Row, Col, Typography } from "antd";
import { CardSmall, DonutChart } from "../Components";
import GraphLine from "../Components/ApexCharts/GraphLine";
import SparkChart from "../Components/ApexCharts/SparkChart";
import BookingTable from "../Components/Table/BookingTable";
import { getDashboardData, getAllBooking } from "../api/booking";
const { Text } = Typography;
const paymentTypeClass = {
  fontWeight: "500",
  color: "#707070",
  fontSize: "12px",
};
const amountClass = { fontWeight: "600", color: "#080305", fontSize: "16px" };


const DashBoardGraph = () => {
  const [selectedToday, setSelectedToday] = useState(true);
  const [selectedYesterday, setSelectedYesterday] = useState(false);
  const [allBookings, setAllBookings] = useState([]);
  const [bookingsCount, setBookingsCount] = useState({});
  const [allBookingsTransaction, setAllBookingsTransaction] = useState(0);
  const [todayBookingsTransaction, setTodayBookingsTransaction] = useState({
    cashBookings: 0,
    cardBookings: 0
  });
  const [yesterdayBookingsTransaction, setYesterdayBookingsTransaction] = useState({
    cashBookings: 0,
    cardBookings: 0
  });
  const [thisMonthSaleAmounts, setThisMonthSaleAmounts] = useState({});
  const [lastMonthSaleAmounts, setLastMonthSaleAmounts] = useState({});
  const [thisMonthBookingsTransaction, setThisMonthBookingsTransaction] = useState(0);
  const [lastMonthBookingsTransaction, setLastMonthBookingsTransaction] = useState(0);
  const [lastWeekSaleAmounts, setLastWeekSaleAmounts] = useState({});
  const [allBookingsSaleAmounts, setAllBookingsSaleAmounts] = useState({});
  const [bestSellingTrips, setBestSellingTrips] = useState([]);

  const titleStyleName1 = {
    borderBottom: selectedToday ? "1px solid #04B2A9" : "1px solid #707070",
    color: selectedToday ? "#04B2A9" : "#707070",
    cursor: "pointer",
  };

  const titleStyleName2 = {
    borderBottom: selectedYesterday ? "1px solid #04B2A9" : "1px solid #707070",
    color: selectedYesterday ? "#04B2A9" : "#707070",
    cursor: "pointer",
    marginLeft: "10px",
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getDashboardData();
        if (response?.data) {
          const bookingsData = response?.data
          setTodayBookingsTransaction(bookingsData.today)
          setYesterdayBookingsTransaction(bookingsData.yesterday)
          let bookingCount = {
            totalBookingsCount: bookingsData.bookingStatus.consentPending + bookingsData.bookingStatus.completed,
            totalPendingsBookingsCount: bookingsData.bookingStatus.consentPending,
            totalDeliveredBookingsCount: bookingsData.bookingStatus.completed
          }
          setBookingsCount(bookingCount)
          setAllBookingsTransaction(bookingsData.sale.allTime)
          setThisMonthBookingsTransaction(bookingsData.sale.thisMonth)
          setLastMonthBookingsTransaction(bookingsData.sale.lastMonth)
          setLastWeekSaleAmounts(bookingsData.sale.lastWeek)
          let bestTrips = []
          Object.keys(bookingsData.tripDetails).forEach(key => {
            const value = bookingsData.tripDetails[key];
            bestTrips.push({ "name": key, quantitySold: value })
          });
          setThisMonthSaleAmounts(bookingsData.sale.thisMonthByDay)
          setLastMonthSaleAmounts(bookingsData.sale.lastMonthByDay)
          setAllBookingsSaleAmounts(bookingsData.sale.allTimeByDay)
          setBestSellingTrips(bestTrips)
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  // useEffect(() => {
  //   if (allOrders.length) {
  //     setBookingsCount(getOrderscount(allOrders));
  //     getAllTransactionByDates(allOrders);
  //     getSaleAmountFromOrders(allOrders);
  //   }
  // }, [allOrders]);

  const cardData = [
    {
      title: `${bookingsCount?.totalBookingsCount ? bookingsCount?.totalBookingsCount : 0}`,
      description: "Total Bookings",
      icon: "icon1",
    },
    {
      title: `${bookingsCount?.totalPendingsBookingsCount ? bookingsCount?.totalPendingsBookingsCount : 0}`,
      description: "Pending Bookings",
      icon: "icon2",
    },
    {
      title: `${bookingsCount?.totalDeliveredBookingsCount ? bookingsCount?.totalDeliveredBookingsCount : 0}`,
      description: "Completed Bookings",
      icon: "icon4",
    },
  ];
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getAllBooking();
        if (response && response.data) {
          setAllBookings(response.data.data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  return (
    <>
      <Row gutter={16} style={{ paddingBottom: "20px" }}>
        <Col span={9} style={{ height: "230px" }}>
          <Card
            bordered={false}
            style={{
              height: "100%",
              border: "1px solid #04B2A9",
              backgroundColor: "#F4FFFE",
              maxWidth: "412px",
            }}
            title={
              <Row gutter={8} justify="center">
                <Col span={8}>
                  <div
                    style={{
                      fontSize: "14px",
                      fontWeight: 500,
                      textAlign: "center",
                    }}
                  >
                    <span
                      style={titleStyleName1}
                      onClick={() => {
                        setSelectedToday(true);
                        setSelectedYesterday(false);
                      }}
                    >
                      Today Bookings
                    </span>
                  </div>
                </Col>
                <Col span={8}>
                  <div
                    style={{
                      fontSize: "14px",
                      fontWeight: 500,
                      textAlign: "center",
                    }}
                  >
                    <span
                      style={titleStyleName2}
                      onClick={() => {
                        setSelectedToday(false);
                        setSelectedYesterday(true);
                      }}
                    >
                      Yesterday Bookings
                    </span>
                  </div>
                </Col>
              </Row>
            }
          >
            <Row gutter={[16, 16]} justify="center">
              <Col span={24} style={{ textAlign: "center" }}>
                <p
                  style={{
                    fontSize: "40px",
                    fontWeight: "700",
                    color: "#080305",
                  }}
                >
                  {selectedToday
                    ? todayBookingsTransaction.cashBookings +
                    todayBookingsTransaction.cardBookings
                    : yesterdayBookingsTransaction.cashBookings +
                    yesterdayBookingsTransaction.cardBookings}
                </p>
              </Col>
            </Row>
            <Row
              gutter={[16, 16]}
              justify="center"
              style={{ marginTop: "20px" }}
            >
              <Col span={8} style={{ textAlign: "center" }}>
                <p style={paymentTypeClass}>
                  <span>CASH</span>
                </p>
              </Col>
              <Col span={8} style={{ textAlign: "center" }}>
                <p style={paymentTypeClass}>
                  <span>CARD</span>
                </p>
              </Col>
            </Row>
            <Row gutter={[16, 16]} justify="center">
              <Col span={8} style={{ textAlign: "center" }}>
                <span style={amountClass}>
                  {selectedToday
                    ? todayBookingsTransaction.cashBookings
                    : yesterdayBookingsTransaction.cashBookings}
                </span>
              </Col>
              <Col span={8} style={{ textAlign: "center" }}>
                <span style={amountClass}>
                  {selectedToday
                    ? todayBookingsTransaction.cardBookings
                    : yesterdayBookingsTransaction.cardBookings}
                </span>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col span={5}>
          <Card
            bordered={false}
            style={{
              height: "100%",
              border: "1px solid #5B93FF",
              backgroundColor: "#EFF5FF",
              width: '100%'
            }}
          >
            <div style={{ color: "#3954A4", marginBottom: "30px" }}>
              <SparkChart color={["#5B93FF"]} data={thisMonthSaleAmounts} />
            </div>
            <p style={{ fontSize: "14px", fontWeight: "bold" }}>This Month</p>
            <p style={{ fontSize: "28px", fontWeight: "bold", width: '100%', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
              {(thisMonthBookingsTransaction).toFixed(2)}
            </p>
          </Card>
        </Col>

        <Col span={5}>
          <Card
            bordered={false}
            style={{
              height: "100%",
              border: "1px solid #E8653B",
              backgroundColor: "#FFE9E1",
              width: '100%'
            }}
          >
            <div style={{ color: "#3954A4", marginBottom: "30px" }}>
              <SparkChart color={["#E8653B"]} data={lastMonthSaleAmounts} />
            </div>
            <p style={{ fontSize: "16px", fontWeight: "bold" }}>Last Month</p>
            <p style={{ fontSize: "28px", fontWeight: "bold", width: '100%', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
              {(lastMonthBookingsTransaction).toFixed(2)}
            </p>
          </Card>
        </Col>
        <Col span={5}>
          <Card
            bordered={false}
            style={{
              height: "100%",
              border: "1px solid #19C32F",
              backgroundColor: "#EFFFF1",
              width: '100%'
            }}
          >
            <div style={{ color: "#3954A4", marginBottom: "30px" }}>
              <SparkChart color={["#19C32F"]} data={allBookingsSaleAmounts} />
            </div>
            <p style={{ fontSize: "16px", fontWeight: "bold" }}>
              All-Time Sales
            </p>
            <p style={{ fontSize: "28px", fontWeight: "bold", width: '100%', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
              {(allBookingsTransaction).toFixed(2)}
            </p>
          </Card>
        </Col>
      </Row>
      <Row gutter={16} style={{ paddingBottom: "20px" }}>
        {cardData.map((data, index) => (
          <Col key={index} span={24 / cardData.length}>
            <CardSmall cardData={data} />
          </Col>
        ))}
      </Row>
      <Row gutter={16} style={{ paddingBottom: "20px" }}>
        <Col span={15}>
          <GraphLine data={lastWeekSaleAmounts} />
        </Col>
        <Col span={8}>
          <DonutChart data={bestSellingTrips} />
        </Col>
      </Row>
      <Row gutter={16} style={{ paddingBottom: "20px" }}>
        <Col span={24}>
          <Text>Recent Bookings</Text>
          <BookingTable allBookings={allBookings?.slice().reverse().slice(0, 4)} />
        </Col>
      </Row>
    </>
  );
};

export default DashBoardGraph;
