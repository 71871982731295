import React, { useEffect, useState } from "react";
import { Button, Switch, Table } from "antd";
import { DeleteFilled, EditFilled } from "@ant-design/icons";
import DeleteModal from "../Modal/DeleteModal";
import { updateTripsPublish } from "../../api/trips";
import dayjs from "dayjs";

const TripsTable = ({ trips, onTripsSelected, onTripsUpdated, searchText }) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [dataSource, setDataSource] = useState([]);
  const [isTripsDeleting, setIsTripsDeleting] = useState(false);
  const [tripsToDelete, setTripsToDelete] = useState(null);
  const [filteredDataSource, setFilteredDataSource] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const handleTableChange = (pagination) => {
    setCurrentPage(pagination.current);
    setPageSize(pagination.pageSize);
  };

  const handleSwitchChange = async (checked, record) => {
    await updateTripsPublish({ id: record.id, isPublished: checked });
    onTripsUpdated();
  };
  
  const handleEditClick = (record) => {
    const { availability, ...recordAvailLess } = record;
    const dayJsDate = availability.map((dateString) => dayjs(dateString));
    onTripsSelected({ availability: dayJsDate, ...recordAvailLess });
  };
  const handleDeleteClick = (record) => {
    setIsTripsDeleting(true);
    setTripsToDelete(record);
  };
  const onItemDeleted = (isitemDeleted) => {
    setIsTripsDeleting(false);
    onTripsUpdated();
  };
  const columns = [
    {
      title: "S.No",
      dataIndex: "sno",
      sorter: (a, b) => a.sno.length - b.sno.length,
    },
    {
      title: "Title Of The Trip",
      dataIndex: "tripTitle",
      sorter: (a, b) => a.tripTitle.length - b.tripTitle.length,
    },
    {
      title: "Destination",
      dataIndex: "destination",
      sorter: (a, b) => a.destination - b.destination,
    },
    {
      title: "Starting Point",
      dataIndex: "startingPoint",
    },
    {
      title: "Original Price",
      dataIndex: "originalPrice",
      sorter: (a, b) => a.originalPrice - b.originalPrice,
    },
    {
      title: "Selling Price",
      dataIndex: "sellingPrice",
      sorter: (a, b) => a.sellingPrice - b.sellingPrice,
    },
    {
      title: "Published",
      dataIndex: "isPublished",
      render: (active, record) => {
        return (
          <Switch
            checked={active}
            onChange={(checked) => handleSwitchChange(checked, record)}
          />
        );
      },
    },
    {
      title: "Featured",
      dataIndex: "feature",
    },
    {
      title: "Actions",
      dataIndex: "action",
      render: (text, record) => {
        return (
          <>
            <Button
              icon={
                <EditFilled style={{ fontSize: "20px", color: "#324966" }} />
              }
              style={{ marginRight: "15px" }}
              onClick={() => handleEditClick(record)}
            />
            <Button
              icon={<DeleteFilled style={{ fontSize: "20px", color: "red" }} />}
              onClick={() => handleDeleteClick(record)}
            />
          </>
        );
      },
    },
  ];

  const onSelectChange = (newSelectedRowKeys) => {
    console.log("selectedRowKeys changed: ", newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const getDataObject = (dataObjArray) => {
    const data = [];
    for (let i = 0; i < dataObjArray?.length; i++) {
      const obj = dataObjArray[i];
      const dataObj = {
        sno: i + 1,
        ...obj,
      };

      data.push(dataObj);
    }
    setDataSource(data);
    setFilteredDataSource(data);
  };
  function filterTripsData(searchText) {
    if (dataSource.length && searchText) {
      const searchTextLower = searchText.toLowerCase();
      const filteredTrips = dataSource.filter((trip) => {
        for (const key in trip) {
          if (
            trip[key] &&
            trip[key].toString().toLowerCase().includes(searchTextLower)
          ) {
            return true;
          }
        }
        return false;
      });
      setFilteredDataSource(filteredTrips);
    } else {
      setFilteredDataSource(dataSource);
    }
  }
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  useEffect(() => {
    getDataObject(trips);
  }, [trips]);

  useEffect(() => {
    filterTripsData(searchText);
  }, [searchText]);
  return (
    <div>
      <Table
        rowSelection={rowSelection}
        columns={columns}
        dataSource={filteredDataSource}
        scroll={{ x: "max-content" }}
        pagination={{
          defaultCurrent: 1,
          total: filteredDataSource?.length,
          current: currentPage,
          pageSize: pageSize,
          onChange: handleTableChange,
        }}
      />
      <DeleteModal
        isProductDeleting={isTripsDeleting}
        deletingObj={tripsToDelete}
        table={"trips"}
        onItemDeleted={onItemDeleted}
        setIsProductDeleting={setIsTripsDeleting}
      />
    </div>
  );
};
export default TripsTable;
