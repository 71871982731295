import React, { useState, useEffect } from "react";
import { Button, Select, Switch, Table } from "antd";
import { DeleteFilled, EditFilled, EyeOutlined } from "@ant-design/icons";
import DeleteModal from "../Modal/DeleteModal";
import { updateDemoStatus } from "../../api/booking";
import authApiService from "../../api/authApiService";
import { sendMail } from "../../api/user";

const DemoBookingsTable = ({
  onDemoBookingSelected,
  fetchData,
  allDemoBookings,
  searchValue,
  setIsViewMode,
}) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [filteredDemoBooking, setFilteredDemoBooking] = useState([]);
  const [allDemoBookingsFormatted, setAllDemoBookingsFormatted] = useState([]);

  const onChange = async (value, record) => {
    var payload = {
      id: record.id,
      vendorId: record.vendorId,
      isApproved: value === "isApproved" ? true : false,
      isRejected: value === "isRejected" ? true : false,
    };
    const updatedData = filteredDemoBooking.map((item) => {
      if (item.id === record.id) {
        return {
          ...item,
          isApproved: value === "isApproved" ? true : false,
          isRejected: value === "isRejected" ? true : false,
        };
      }
      return item;
    });
    const response = await updateDemoStatus(payload);
    console.log(response);
    if (response) {
      if (payload.isApproved) {
        const { data } = await authApiService.get(
          `/auth/vendor/${record.vendorId}`
        );
        if (data) {
          let email_payload = {
            to: [`${data.data.email}`, `${record.email}`],
            from: "support@lilypad.co.in",
            subject: `Your demo request is ${value === "isApproved" ? "approved" : "rejected"
              }`,
            html: "<p> test email </p>",
          };
          sendMail(email_payload);
        }
      }
    }
    if (payload.isRejected) {
      let email_payload = {
        to: response.demo.email,
        from: "support@lilypad.co.in",
        subject: `Your demo request is ${value === "isApproved" ? "approved" : "rejected"
          }`,
        html: "<p> test email </p>",
      };
      sendMail(email_payload);
    }

    setFilteredDemoBooking(updatedData);
  };

  const handleViewClick = (record) => {
    let bookings = allDemoBookings.filter((item) => item.id === record.id);
    onDemoBookingSelected(bookings[0]);
    setIsViewMode(true);
  };

  const columns = [
    {
      title: "NAME",
      dataIndex: "name",
      sorter: (a, b) => a.name.length - b.name.length,
    },
    {
      title: "EMAIL",
      dataIndex: "email",
    },
    {
      title: "PHONE",
      dataIndex: "phone",
    },
    {
      title: "STATUS",
      dataIndex: "status",
      render: (active, record) => {
        return (
          <Select
            name="status"
            placeholder="Status"
            options={[
              { label: "Approve", value: "isApproved" },
              { label: "Reject", value: "isRejected" },
            ]}
            className="PU-input"
            defaultValue={
              record.isApproved
                ? "Approve"
                : record.isRejected
                  ? "Reject"
                  : null
            }
            style={{ width: "125px", color: "#000" }}
            onChange={(value) => onChange(value, record)}
          ></Select>
        );
      },
    },
    {
      title: "ACTIONS",
      dataIndex: "action",
      render: (text, record) => {
        return (
          <>
            <Button
              style={{ margin: "0 5px" }}
              icon={<EyeOutlined style={{ fontSize: "20px", color: "blue" }} />}
              onClick={() => handleViewClick(record)}
            />
          </>
        );
      },
    },
  ];

  const formatDate = (date) => {
    const formattedDate = new Date(date);
    return formattedDate.toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  useEffect(() => {
    const filteredDemoBooking = allDemoBookingsFormatted.filter(
      (bookingObj) => {
        if (searchValue) {
          const searchLowerCase = searchValue.toLowerCase();
          return (
            (bookingObj.name &&
              bookingObj.name.toLowerCase().includes(searchLowerCase)) ||
            (bookingObj.email &&
              bookingObj.email.toLowerCase().includes(searchLowerCase)) ||
            (bookingObj.phone &&
              bookingObj.phone.toLowerCase().includes(searchLowerCase))
          );
        }
        return true;
      }
    );

    setFilteredDemoBooking(filteredDemoBooking);
  }, [searchValue]);

  useEffect(() => {
    const formattedData = allDemoBookings.map((bookingObj, index) => ({
      id: bookingObj.id,
      name: `${bookingObj.firstName} ${bookingObj.lastName}`,
      email: bookingObj.email,
      phone: bookingObj.phoneNumber,
      vendorId: bookingObj.vendorId,
      isApproved: bookingObj.isApproved,
      isRejected: bookingObj.isRejected,
      action: "",
    }));
    setAllDemoBookingsFormatted(formattedData);
    setFilteredDemoBooking(formattedData);
  }, [allDemoBookings]);

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const hasSelected = selectedRowKeys.length > 0;

  return (
    <div>
      <Table
        columns={columns}
        dataSource={filteredDemoBooking}
        scroll={{ x: "max-content" }}
      />
    </div>
  );
};

export default DemoBookingsTable;
