import axios from "axios";
// import store from "../store"; // Import your Redux store
import Cookies from "universal-cookie";
import { message } from "antd";
const cookie = new Cookies();

const bookingApiService = axios.create({
  baseURL:
    process.env.REACT_APP_NODE_ENV === "development"
      ? "https://orderapi.travelpocket.in/v1/api/order"
      : "https://orderapi.travelpocket.in/v1/api/order",
});

// Request interceptor to include auth token in specific requests
bookingApiService.interceptors.request.use(
  (config) => {
    // const authToken = store.getState().auth.authToken;
    // let authToken = localStorage.getItem("auth_token");
    const authToken = cookie.get("token");

    if (authToken) {
      // Check if the request URL requires authorization (e.g., cart-related endpoints)
      if (
        config.url.includes("demo") ||
        config.url.includes("booking") ||
        config.url.includes("order")
      ) {
        config.headers["Authorization"] = `Bearer ${authToken}`;
      }
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Response interceptor to handle 403 errors and logout user
bookingApiService.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 403) {
      cookie.remove("token");
      message.error("Your session has expired. Please log in again.")
    }
    return Promise.reject(error);
  }
);

export default bookingApiService;
