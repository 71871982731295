import axios from "axios";
import Cookies from "universal-cookie";
const cookie = new Cookies();

const tourApiService = axios.create({
  baseURL:
    process.env.NODE_ENV === "development"
      ? "https://productapi.travelpocket.in/v1/api/product"
      : "https://productapi.travelpocket.in/v1/api/product", // Your API base URL
});

// Request interceptor to include auth token in specific requests
tourApiService.interceptors.request.use(
  (config) => {
    // let authToken = localStorage.getItem('auth_token');
    // config.headers['Content-Type'] = 'multipart/form-data';
    const authToken = cookie.get("token");

    if (authToken) {
      // Check if the request URL requires authorization (e.g., cart-related endpoints)
      if (
        config.url.includes("product") ||
        config.url.includes("/cart/") ||
        config.url.includes("/another-protected-endpoint")
      ) {
        config.headers["Authorization"] = `Bearer ${authToken}`;
      }
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default tourApiService;
