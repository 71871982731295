import React, { useState, useEffect } from "react";
import { Button, Select, Switch, Table, message } from "antd";
import { DeleteFilled, EditFilled, EyeOutlined } from "@ant-design/icons";
import DeleteModal from "../Modal/DeleteModal";
import { updateQutations, updateStatus } from "../../api/booking";
import authApiService from "../../api/authApiService";
import { sendMail } from "../../api/user";

const QuotationTable = ({
  onQuotationSelected,
  fetchData,
  allQuotations,
  searchValue,
  setIsViewMode,
}) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [filteredQuotations, setFilteredQuotations] = useState([]);
  const [allQuotationsFormatted, setAllQuotationsFormatted] = useState([]);

  const onChange = async (value, record) => {
    var payload = {
      id: record.id,
      productId: record.productId,
      vendorId: record.vendorId,
      isApproved: value === "isApproved" ? true : false,
      isRejected: value === "isRejected" ? true : false,
    };
    console.log(payload, record);
    const updatedData = filteredQuotations.map((item) => {
      if (item.id === record.id) {
        return {
          ...item,
          isApproved: value === "isApproved" ? true : false,
          isRejected: value === "isRejected" ? true : false,
        };
      }
      return item;
    });
    const response = updateQutations(payload);
    if (response) {
      message.success(
        `Qutation request ${value === "isApproved" ? "approved" : "rejected"}`
      );
      // const { data } = await authApiService.get(`/auth/vendor/${record.vendorId}`);
      // if(data){
      // 	var email_payload = {
      // 		"to": [`${data.data.email}`, `${record.email}`],
      // 		"from": "support@lilypad.co.in",
      // 		"subject": `Your demo request is ${value === 'isApproved' ? "approved" : "rejected"}`,
      // 		"html": "<p> test email </p>"
      // 	}
      // 	sendMail(email_payload)
      // }
    }
    setFilteredQuotations(updatedData);
  };

  const handleViewClick = (record) => {
    let bookings = allQuotations.filter((item) => item.id === record.id);
    onQuotationSelected(bookings[0]);
    setIsViewMode(true);
  };

  const columns = [
    {
      title: "NAME",
      dataIndex: "name",
      sorter: (a, b) => a.name.length - b.name.length,
    },
    {
      title: "EMAIL",
      dataIndex: "email",
    },
    {
      title: "PHONE",
      dataIndex: "phone",
    },
    {
      title: "STATUS",
      dataIndex: "status",
      render: (active, record) => {
        return (
          <Select
            name="status"
            placeholder="Status"
            options={[
              { label: "Approve", value: "isApproved" },
              { label: "Reject", value: "isRejected" },
            ]}
            className="PU-input"
            defaultValue={
              record.isApproved
                ? "Approve"
                : record.isRejected
                  ? "Reject"
                  : null
            }
            style={{ width: "125px", color: "#000" }}
            onChange={(value) => onChange(value, record)}
          ></Select>
        );
      },
    },
    {
      title: "ACTIONS",
      dataIndex: "action",
      render: (text, record) => {
        return (
          <>
            <Button
              style={{ margin: "0 5px" }}
              icon={<EyeOutlined style={{ fontSize: "20px", color: "blue" }} />}
              onClick={() => handleViewClick(record)}
            />
          </>
        );
      },
    },
  ];

  useEffect(() => {
    const filteredQuotations = allQuotationsFormatted.filter((bookingObj) => {
      if (searchValue) {
        const searchLowerCase = searchValue.toLowerCase();
        return (
          (bookingObj.name &&
            bookingObj.name.toLowerCase().includes(searchLowerCase)) ||
          (bookingObj.email &&
            bookingObj.email.toLowerCase().includes(searchLowerCase)) ||
          (bookingObj.phone &&
            bookingObj.phone.toLowerCase().includes(searchLowerCase))
        );
      }
      return true;
    });

    setFilteredQuotations(filteredQuotations);
  }, [searchValue]);

  useEffect(() => {
    const formattedData = allQuotations?.map((bookingObj, index) => ({
      id: bookingObj.id,
      name: `${bookingObj.userName}`,
      productName: bookingObj.productName,
      userLocation: bookingObj.userLocation,
      productId: bookingObj.productId,
      email: bookingObj.userEmail,
      vendorId: bookingObj.vendorId,
      isApproved: bookingObj.isApproved,
      isRejected: bookingObj.isRejected,
      action: "",
    }));
    setAllQuotationsFormatted(formattedData);
    setFilteredQuotations(formattedData);
  }, [allQuotations]);

  return (
    <div>
      <Table
        columns={columns}
        dataSource={filteredQuotations}
        scroll={{ x: "max-content" }}
      />
    </div>
  );
};

export default QuotationTable;
