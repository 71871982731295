import {
  PlusOutlined,
  SearchOutlined
} from "@ant-design/icons";
import { Button, Col, Input, Row } from "antd";
import { useEffect, useState } from "react";
import DestinationTable from "../Components/Table/DestinationTable";
import AddDestination from "../Components/AddDestination";
import { getAllDestination } from "../api/destination";

const buttonStyle = {
  minWidth: "110px",
  height: "40px",
  padding: "20px, 0px, 20px, 0px",
  borderRadius: "6px",
  marginRight: "10px",
};

const DestinationPage = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [destinations, setdestinations] = useState([]);
  const [selectedDestination, setSelectedDestination] = useState(null);
  const [searchText, setSearchText] = useState("");
  // const handleMenuClick = (e) => {
  //   console.log("click", e);
  // };
  const onDestinationSelected = (destination, mode) => {
    if(mode === 'edit') {
      setSelectedDestination(destination);
      setIsDrawerOpen(true);
    }
  };
  const onDestinationUpdated = () => {
    getDestinationsFromApi();
  }
  const getDestinationsFromApi = async() => {
    try{
      const destinationData =  await getAllDestination();
      setdestinations(destinationData?.data?.data)
    } catch(error){
      console.error('Error fetching destinations:', error);
    }
  }
  useEffect(()=>{
    getDestinationsFromApi()
  }, [])
  return (
    <>
      <div>
        <Row gutter={16} style={{ marginTop: "20px" }}>
          <Col span={18}>
            {/* Content for the first column in the first row */}
            <Row gutter={16}>
              <Col>
                <Input
                  size="large"
                  placeholder="Search Destination"
                  suffix={<SearchOutlined />}
                  onChange={(input) => setSearchText(input.target.value)}
                />
              </Col>
            </Row>
          </Col>
          <Col span={6} style={{ textAlign: "right" }}>
            {/* Content for the second column in the first row */}
            <Button
              icon={<PlusOutlined />}
              type="primary"
              style={{ ...buttonStyle, backgroundColor: "#324966" }}
              onClick={(e) => {
                console.log("add button clicked");
                setSelectedDestination(null);
                setIsDrawerOpen(true);
              }}
            >
              Add Destination
            </Button>
          </Col>
        </Row>
        <Row gutter={16} style={{ marginTop: "20px" }}>
          <Col span={24}>
            <DestinationTable searchText={searchText} destinations={destinations} onDestinationSelected={onDestinationSelected} onDestinationUpdated={onDestinationUpdated}/>
          </Col>
        </Row>
        <Row gutter={16} style={{ marginTop: "20px" }}>
          <Col span={24}>
           <AddDestination isDrawerOpen={isDrawerOpen} setIsDrawerOpen={setIsDrawerOpen} selectedDestination={selectedDestination} onDestinationUpdated={onDestinationUpdated} />
          </Col>
        </Row>
      </div>
    </>
  );
};

export default DestinationPage;
