import tourApiService from "./tourApiService";

// GET request
export const getAllDestination = async () => {
    return tourApiService.get('destination');
};
// GET destination by id
export const getDestination = async (destinationId) => {
    return tourApiService.get(`destination/${destinationId}`, "GET");
  };
  
  // create destination
  export const createDestination = async (data) => {
    return tourApiService.post("destination", data);
  };
  
  // update destination
  export const updateDestination = async (data) => {
    return tourApiService.patch(`destination`, data);
  };
  
  // DELETE destination
  export const deleteDestination = async (destinationId) => {
    return tourApiService.delete(`destination/${destinationId}`, "DELETE");
  };