import { CURRENT_PAGE, SET_ACTIVE_SECTION } from "./constant";

export const currentPage = (payload) => ({
  type: CURRENT_PAGE,
  payload,
});

export const setActiveSection = (section) => {
  return {
    type: SET_ACTIVE_SECTION,
    payload: section,
  };
};
